import React, { Component } from 'react'

import Axios from 'axios';
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css"
// import "@fancyapps/fancybox/dist/jquery.fancybox";
export default class CampMedia extends Component {
    state = {
        gallery: [],
        loaded: false,
        scrollPage: 1,
        videoId: this.props.match.params.id,
        media: this.props.match.params.media,
        video_gallery: [

        ]
    }
    // getImages = (page = 1, limit = 20) => {
    //     axios.get(`https://picsum.photos/v2/list?page=${page}&limit=${limit}`).then(({ data }) => {
    //         let gallery = this.state.gallery.concat(data);
    //         this.setState({ gallery, loaded: true })

    //     })
    // }
    trackScrolling = () => {
        // if (window.scrollY + 100 >= window.innerHeight && this.state.loaded) {
        //     this.setState({ loaded: false })
        //     this.getImages(this.state.scrollPage + 1, 10)
        //     this.setState({ scrollPage: this.state.scrollPage + 1 })
        // }
    }

    handlePreach = (id = this.state.videoId, media = this.state.media) => {
        // console.log(`${year}/${place_id}/${category_id}/0/${page}`)
        Axios.get(`https://q.qristianinews.ge/search/all/camps/${media === "videos" ? "videos" : "Images"}/${id}`)
            .then(({ data }) => {
                console.log(data)
                this.setState({
                    gallery: data.videos,
                    loaded: true
                })
            })
            .catch(err => {

            })
    }
    componentWillMount() {
        this.handlePreach()
        document.addEventListener('scroll', this.trackScrolling);

    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }
    render() {
        const { videoId, media } = this.state;
        return (
            <section id="camp-media" className="container">
                <div className="camp-hero" >
                    <img src="https://scontent.ftbs5-2.fna.fbcdn.net/v/t1.0-9/39211032_2369699019721888_2455055537490886656_o.jpg?_nc_cat=101&_nc_sid=dd9801&_nc_eui2=AeF0sRxAWaRSGYPWcVsiaewBXNmcRXBg1wJc2ZxFcGDXAvyKorqy_rwzKAfp2lxZRgJmsZwAHUPVXsZMh0jiGaug&_nc_ohc=MTBm55e5FxIAX96LkgW&_nc_ht=scontent.ftbs5-2.fna&oh=0ad9ef5f3fd53d3e00f36ad0a2e6dfc5&oe=5EE573AD" alt="" />
                    <div className="center-camp-title">
                        <h1 className="camp-hero-title">ჩემი ტრანსფორამცია</h1>
                        <h3 className="camp-hero-subtitle">ბაზალეთი - 2018<br /></h3>
                    </div>
                </div>
                <div className="row camp-photo-gallery">
                    {
                        this.state.gallery.map(({ fileurl, sources, download_url }, i) => {
                            return (
                                <div className="camp-thumbmails col-3" key={i}>
                                    {
                                        media === "videos" ?
                                            <React.Fragment>
                                                <a data-fancybox="gallery" className="camp-gallery-item" href={`#myVideo-${i}`}>
                                                    <video >
                                                        <source src={fileurl} />
                                                        Your browser doesn't support video
                                                    </video>
                                                </a>
                                                <video id={`myVideo-${i}`} className="d-none" >
                                                    <source src={fileurl} />
                                                        Your browser doesn't support video
                                                </video>
                                            </React.Fragment>
                                            :
                                            <a data-fancybox className="camp-gallery-item" href={sources}>
                                                <img src={download_url} />
                                            </a>

                                    }
                                </div>


                            )
                        })
                    }

                    <div id="loading-page" className="camp-gallery-loader">
                        <p className="loader">Loading...</p>
                    </div>
                </div>


            </section>
        )
    }
}
