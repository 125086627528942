import React, { Component } from 'react'
import { Link } from "react-router-dom";
import axios from "axios";

class Leaders extends Component {
    state={
        leaders: []
    }


    getLeaders(){
        axios.get("https://q.qristianinews.ge/search/all/getaboutus/leaders").then(res=>{
            this.setState({leaders:res.data.leaders})
        })
    }
    componentDidMount(){
        this.getLeaders()
    }
  render() {
      const { leaders } = this.state;
    return (
        <section id="leaders" className="row container">
            {
                leaders !== [] && leaders.map(({name, position, slug, imgurl}, i)=>{
                    return(
                        <div className={`pastor-item col-4 animate__fadeInUp custom__delay-${i+2} animate__animated`} key={i} >
                            <Link to={"/about_us/leaders/"+slug} >
                                <div className="pastor-avatar">
                                    <img src={imgurl} alt=""/>
                                </div>
                                <div className="pastor-details">
                                    <p>{position}</p>
                                    <h2>{name}</h2>
                                </div>
                            </Link>
                        </div>
                    )
                })                
            }
           
        </section>
    )
  }
}

export default Leaders;