import React, { Component } from 'react';
import SubHeader from '../partials/subheader';
import { Route, Switch } from "react-router-dom";

import "../../assets/scss/pages/youth.scss";

import Preach from "./preach"
import Camp from "./camp";
import campMedia from './campMedia';
import Activities from "./activities";
import Post from "../partials/post";
import Mettings from "../youth/mettings";
import About from "../youth/about";


export default class Youth extends Component {
    state = {
        header_menu: [
            {
                name: "მთავარი",
                link:"/youth/preach/0/1",
            },
            {
                name: "ბანაკი",
                link: "/youth/camp/1"
            },
            {
                name: "აქტივობები",
                link: "/youth/activities/1"
            },
            {
                name: "შეხვედრები",
                link: "/youth/mettings"
            },
            {
                name: "ჩვენს შესახებ",
                link: "/youth/about_us"
            }
        ]
    }
    
    componentWillMount(){
        if(this.props.match.path==="/youth/") {
            this.props.history.push("/youth/preach/0/1")
        }
    } 
    
    componentWillUpdate(){
        if(this.props.match.path==="/youth/") {
            this.props.history.push("/youth/preach/0/1")
        }
    }

    render() {
        const { header_menu } = this.state;
        const { match } = this.props;
        return (
            <main>
                <SubHeader menu={header_menu} bg={"https://images.pexels.com/photos/1629236/pexels-photo-1629236.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"} location={match.path}/>
                <Switch >
                    <Route exact path="/youth/preach/:preacher/:page" component={Preach} />
                    <Route exact path="/youth/camp/" component={Camp}/>
                    <Route exact path="/youth/camp/:media/:camp/" component={campMedia}/>
                    <Route exact path="/youth/camp/:page/" component={Camp}/>
                    <Route exact path="/youth/camp/:page/:year/" component={Camp}/>
                    <Route exact path="/youth/camp/:page/:year/:place/" component={Camp}/>
                    <Route exact path="/youth/camp_details/:slug/" component={Camp}/>
                    <Route exact path="/youth/activities/" component={Activities}/>
                    <Route exact path="/youth/activities/:page/" component={Activities}/>
                    <Route exact path="/youth/activitie/:slug/"  component={Post}/>
                    <Route exact path="/youth/mettings/"  component={Mettings}/>
                    <Route exact path="/youth/about_us/"  component={About}/>
                </Switch>
            </main>
        );
    }
}
