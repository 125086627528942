import React, { Component } from "react";
import {
  ExpandMoreRounded,
  ExpandLessRounded,
  DateRangeRounded,
} from "@material-ui/icons";
import axios from "axios";

class History extends Component {
  state = {
    active_year: null,
    year_range: 0,
    year_len: 10,
    years: [],
  };

  yearSlider = (pos) => {
    const { year_range, year_len, years } = this.state;
    if (pos === 0 && year_range !== 0) {
      this.setState({ year_range: year_range - 1 });
    }
    if (pos === 1 && year_range !== years.length - year_len) {
      this.setState({ year_range: year_range + 1 });
    }
  };

  getHistory() {
    axios
      .get("https://q.qristianinews.ge/search/all/getaboutus")
      .then((res) => {
        this.setState({
          years: res.data.aboutus,
          active_year: res.data.aboutus[0].name,
        });
      });
  }
  componentDidMount() {
    this.getHistory();
  }
  render() {
    const { years, active_year, year_range, year_len } = this.state;
    const { yearSlider } = this;
    let story = years.find(({ name }) => name === active_year);
    return (
      <section
        id="history"
        className="blur"
        style={{ backgroundImage: `url('${story && story.imgurl}')` }}
      >
        <aside className="about-aside">
          <DateRangeRounded className="about-calendar-icon" />
          <ul className="year-list">
            <li className="year-up">
              <button
                className={year_range === 0 ? "op-0" : ""}
                onClick={() => {
                  yearSlider(0);
                }}
              >
                <ExpandLessRounded />
              </button>
            </li>
            {years
              .slice(0 + year_range, year_len + year_range)
              .map(({ name }, i) => {
                return (
                  <li
                    key={i}
                    onClick={() => {
                      this.setState({ active_year: name });
                    }}
                    className={`need-obj animate__fadeInLeft custom__delay-${
                      i + 2
                    } animate__animated ${
                      active_year === name ? "active" : ""
                    }`}
                  >
                    <button>{name}</button>
                  </li>
                );
              })}
            <li className="year-up">
              <button
                className={year_range === years.length - year_len ? "op-0" : ""}
                onClick={() => {
                  yearSlider(1);
                }}
              >
                <ExpandMoreRounded />
              </button>
            </li>
          </ul>
        </aside>

        <article className="church-story  container">
          <h1 className="animate__fadeInUp animate__animated">
            {story && story.name}
          </h1>
          <p className="animate__fadeInDown animate__animated">
            {story && story.body}
          </p>
        </article>
      </section>
    );
  }
}

export default History;
