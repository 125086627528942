import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

// MATERIAL UI
import SubHeader from '../partials/subheader';
// COMPONENTS
import Preach from './preach';
import AudioArchive from './audioArchive';
import PreachVideo from '../preach-video/index';

class MainWorship extends Component {
    state = {
        header_menu: [
            {
                name: "ქადაგება",
                link: "/main_worship/preach/0/1",
            },
            {
                name: "არქივი",
                sub: [
                    {
                        name: "ვიდეო",
                        link: "/main_worship/archive/video/0/1/",
                    },
                    {
                        name: "აუდიო",
                        link: "/main_worship/archive/audio/0/",
                    },

                ]
            }
        ]
    }
    handleArchive = () => {
        this.props.propsdata.history.push(`/main_worship/archive/video/0/1/`)
    }
    handleAudio = () => {
        this.props.propsdata.history.push(`/main_worship/archive/audi/0/`)
    }
    render() {
        const { header_menu } = this.state;
        const { match } = this.props;
        return (
            <main>
                <SubHeader menu={header_menu} bg={"https://images.pexels.com/photos/1629236/pexels-photo-1629236.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"} location={match.path} />
                <Switch >
                    <Route exact path="/main_worship/archive/audio/" component={AudioArchive} />
                    <Route exact path="/main_worship/archive/audio/:preacher/" component={AudioArchive} />
                    <Route exact path="/main_worship/archive/audio/:preacher/:year/" component={AudioArchive} />
                    <Route exact path="/main_worship/archive/audio/:preacher/:year/:slug" component={AudioArchive} />
                    <Route exact path="/main_worship/" component={Preach} />
                    <Route exact path="/main_worship/:preach/" component={Preach} />
                    <Route exact path="/main_worship/:preach/:preacher/" component={Preach} />
                    <Route exact path="/main_worship/:preach/:preacher/:page" component={Preach} />
                    <Route exact path="/main_worship/:preach/" component={Preach} />
                    <Route exact path="/main_worship/:preach/video/" component={Preach} />
                    <Route exact path="/main_worship/:preach/video/:preacher/:page/" component={Preach} />

                    <Route exact path="/preach-video/:slug/" component={PreachVideo} />
                </Switch>
            </main>
        );
    }
}

export default MainWorship;