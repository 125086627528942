import React, { Component } from 'react';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';

class News extends Component {
    state = {
        news: [
            {
                'ID': 0,
                'img': {
                    'src': 'http://qristiani.ge/q/wp-content/uploads/2014/11/cross300X254.jpg',
                    'alt': 'test'
                }, 
                'title': 'მწამს ერთი ღმერთი, მამა ყოვლისა მპყრობელი'

            },
            {
                'ID': 1,
                'img': {
                    'src': 'http://qristiani.ge/q/wp-content/uploads/2018/10/44468177_2094282947283747_8934369687576248320_o.jpg',
                    'alt': 'test'
                }, 
                'title': 'სახარების რწმენის ეკლესიის წარსული, აწმყო და მომავალი'

            },
            {
                'ID': 2,
                'img': {
                    'src': 'http://qristiani.ge/q/wp-content/uploads/2018/12/Banaki-dghe-7-600x400.jpg',
                    'alt': 'test'
                }, 
                'title': 'ეკლესია – ქრისტეს სხეული'

            },
            {
                'ID': 3,
                'img': {
                    'src': 'http://qristiani.ge/q/wp-content/uploads/2018/06/Prokrast.jpg',
                    'alt': 'test'
                }, 
                'title': 'სიკეთე თუ ღმერთი? – ჯონ ბევირი'

            },
            {
                'ID': 4,
                'img': {
                    'src': 'http://qristiani.ge/q/wp-content/uploads/2018/11/Mirtag-600x400.jpg',
                    'alt': 'test'
                }, 
                'title': 'საბავშვო ბიბლია – სწორი არჩევანი'

            },
            {
                'ID': 5,
                'img': {
                    'src': 'http://qristiani.ge/q/wp-content/uploads/2017/08/Untitled-1.jpg',
                    'alt': 'test'
                }, 
                'title': 'ეკლესია – ქრისტეს სხეული'

            }
        ]
    }

    render() {
        return (
            <section id="news">
                {/* <h1>სიახლეები</h1> */}
                <div className="newsContainer row no-gutters ">
                    {
                        this.state.news.map( (object, i) =>{
                            return(
                                <article className={`newsObject animate__fadeInDown custom__delay-${i+2} animate__animated`} key="{object.ID}">
                                        <PlayCircleOutline size={30}/>
                                        <img src={object.img.src} className="newsImage" alt={object.img.alt} />
                                        <div className="shadow-text">
                                            <h1>{object.title}</h1>
                                        </div>
                                </article>
                            )
                        })

                    }
                </div>
            </section>
        );
    }
}

export default News;
