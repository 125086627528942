import React, { Component } from 'react';

import Pagination from "material-ui-flat-pagination";
import {LocationOn, SlowMotionVideo, PhotoLibrary} from '@material-ui/icons';
import { TextField, MenuItem, InputLabel, Select, FormControl} from '@material-ui/core';
import {Link} from "react-router-dom";

class Camp extends Component {

    state = {
        pageCount: 4,
        page: this.props.match.params.page ? this.props.match.params.page : 1 ,
        year: this.props.match.params.year ? this.props.match.params.year: 0,
        place: this.props.match.params.place ? this.props.match.params.place : 0,
        camps: [
            {
                name: "100%-ით ოკუპირებული",
                location_id: 5,
                image: true,
                video: true,
                year: 2019,
                img: "https://images.unsplash.com/photo-1532876924456-d6460f2f56a7?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
                slug: "chemi-transformatsia",
                video_img: "https://images.pexels.com/photos/3776872/pexels-photo-3776872.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
                photos_img: "https://images.pexels.com/photos/2820725/pexels-photo-2820725.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"

            },
            {
                name: "ჩემი ტრანსფორმაცია",
                location_id: 6,
                image: true,
                video: true,
                year: 2018,
                img: "https://scontent.ftbs5-2.fna.fbcdn.net/v/t1.0-9/39211032_2369699019721888_2455055537490886656_o.jpg?_nc_cat=101&_nc_sid=dd9801&_nc_eui2=AeF0sRxAWaRSGYPWcVsiaewBXNmcRXBg1wJc2ZxFcGDXAvyKorqy_rwzKAfp2lxZRgJmsZwAHUPVXsZMh0jiGaug&_nc_ohc=MTBm55e5FxIAX96LkgW&_nc_ht=scontent.ftbs5-2.fna&oh=0ad9ef5f3fd53d3e00f36ad0a2e6dfc5&oe=5EE573AD",
                slug: "chemi-transformatsia",
                video_img: "https://images.unsplash.com/photo-1511993807578-701168605ad3?ixlib=rb-1.2.1&auto=format&fit=crop&w=1347&q=80",
                photos_img: "https://images.pexels.com/photos/2820725/pexels-photo-2820725.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"

            },
            {
                name: "180 გრადუსით",
                location_id: 6,
                image: true,
                video: true,
                year: 2017,
                img: "https://qristiani.ge/q/wp-content/uploads/2017/08/600-400-WEB-Cover-Banakis-klipi1.jpg",
                slug: "chemi-transformatsia",
                video_img: "https://images.pexels.com/photos/3776872/pexels-photo-3776872.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
                photos_img: "https://images.unsplash.com/photo-1511993807578-701168605ad3?ixlib=rb-1.2.1&auto=format&fit=crop&w=1347&q=80"

            },
            {
                name: "იცხოვრე მიზნით",
                location_id: 5,
                image: true,
                video: true,
                year: 2016,
                img: "https://images.unsplash.com/photo-1533736233029-f35f369514ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
                slug: "chemi-transformatsia",
                video_img: "https://images.unsplash.com/photo-1511993807578-701168605ad3?ixlib=rb-1.2.1&auto=format&fit=crop&w=1347&q=80",
                photos_img: "https://images.pexels.com/photos/2820725/pexels-photo-2820725.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"

            },
            {
                name: "ჩემი ტრანსფორმაცია",
                location_id: 5,
                image: true,
                video: true,
                year: 2015,
                img: "https://images.pexels.com/photos/2819554/pexels-photo-2819554.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
                slug: "chemi-transformatsia",
                video_img: "https://images.pexels.com/photos/3776872/pexels-photo-3776872.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
                photos_img: "https://images.pexels.com/photos/2820725/pexels-photo-2820725.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"

            },
            {
                name: "ჩემი ტრანსფორმაცია",
                location_id: 4,
                image: true,
                video: true,
                year: 2014,
                img: "https://images.unsplash.com/photo-1533632359083-0185df1be85d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=675&q=80",
                slug: "chemi-transformatsia",
                video_img: "https://images.pexels.com/photos/3776872/pexels-photo-3776872.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
                photos_img: "https://images.pexels.com/photos/2820725/pexels-photo-2820725.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"

            }
        ],
        locations:[
            {
                ID: 1,
                title: 'ცემი',
            },
            {
                ID: 2,
                title: 'ბაკურიანი',
            },
            {
                ID: 3,
                title: 'ბაღდათი',
            },
            {
                ID: 4,
                title: 'ურეკი',
            },
            {
                ID: 5,
                title: 'ატენი',
            },
            {
                ID: 6,
                title: 'ბაზალეთი',
            },
        ],
        years:[
            2020, 2019, 
            2018, 2017, 
            2016, 2015, 
            2014, 2013, 
            2012, 2011, 
            2010, 2009, 
            2008, 2007
        ]
    }

    handleYear = ({target}) =>{
        let year = target.value
        this.setState({year})
        const {page,place} = this.state;
        this.props.history.push(`/youth/camp/${page}/${year}/${place}/`)    
    }

    handleLocation = ({target}) =>{
        let place = target.value
        this.setState({place})
        const {page, year} = this.state;
        this.props.history.push(`/youth/camp/${page}/${year}/${place}/`)    
    }

    handlePagination= (_el,page_offset)=>{
        let page = (page_offset/6)+1;
        this.setState({page})
        const {year, place} = this.state;
        this.props.history.push(`/youth/camp/${page}/${year}/${place}/`)        
    }
    render() {
        const { pageCount, page, camps, locations, years, place, year} = this.state;
        const {handleYear,handleLocation, handlePagination} = this;
        return (
            <section id="camps">
                  <div className="filter">
                    <div className="filter-container container">

                            <div className="preach-calendar">
                                <FormControl >
                                    <InputLabel >ლოკაცია</InputLabel>
                                    <Select
                                        value={place}
                                        onChange={handleLocation}

                                    >
                                        <MenuItem value={0}>ყველა</MenuItem>
                                        {
                                            locations.map(({ID, title})=>{
                                                return <MenuItem value={ID} key={ID}>{title}</MenuItem>
                                            })
                                            
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl >
                                    <InputLabel >წელი</InputLabel>
                                    <Select
                                        value={year}
                                        onChange={handleYear}

                                    >
                                        <MenuItem value={0}>ყველა</MenuItem>
                                        {
                                            years.map(year=>{
                                                return <MenuItem value={year} key={year}>{year}</MenuItem>
                                            })
                                            
                                        }                                    
                                    </Select>
                                </FormControl>
                                <FormControl >
                                    <TextField id="standard-search" value=" " label="ძებნა" type="search" />
                                </FormControl>
                            </div>
                        </div>
                    </div>

                   <div className="camp-container row container mx-auto">
                        {   
                            camps.map((camp,i)=>{
                                return(
                                    <article key={i} className={`camp-item animate__fadeInLeft custom__delay-${i+2} animate__animated`} >
                                        <div className="camp-img-sec">
                                            <img src={camp.img} alt={camp.name}/>
                                        </div>
                                        <div className="camp-details">
                                            <h3 className="camp-year">{camp.year}</h3>
                                            <h2 className="camp-title">{camp.name}</h2>
                                            <div className="camp-location-section">
                                                <LocationOn/>
                                                <h3 className="camp-loc-title">{locations.find(nav => camp.location_id=== nav.ID).title}</h3>
                                            </div>
                                            <div className="gallery-section">
                                                {
                                                    camp.video_img !== ""?
                                                    <Link to="/youth/camp/video/aq_unda_iyos_slagi">
                                                        <div className="gallery-link-item">
                                                            <SlowMotionVideo/>
                                                            <img src={camp.video_img} alt="camp gallery"/>
                                                        </div>
                                                    </Link>
                                                    : ""
                                                }
                                                {
                                                    camp.photos_img !== ""?
                                                    <Link to="/youth/camp/photo/aq_unda_iyos_slagi">
                                                        <div className="gallery-link-item">
                                                            <PhotoLibrary/>
                                                            <img src={camp.photos_img} alt="camp gallery"/>
                                                        </div>
                                                    </Link>
                                                    :""
                                                }
                                            </div>
                                        </div>
                                    </article>
                                )
                            })
                        }
                   </div>
                    
                    <div className="pag-sec">
                        {
                            pageCount !== 1 ?
                            <Pagination
                                limit={6}
                                offset={(page-1)*6}
                                previousPageLabel="&#10094; წინა"
                                nextPageLabel="შემდეგი &#10095;"
                                total={pageCount*6}
                                onClick={handlePagination}
                            />
                            :
                            <div className="mb-5"></div>
                        }
                    </div>
            </section>
        );
    }
}

export default Camp;