import React, { Component } from 'react';
import SubHeader from '../partials/subheader';
import { Route, Switch } from "react-router-dom";

import "../../assets/scss/pages/youth.scss";

import Camp from "./camp";
import campMedia from './campMedia';


export default class Camps extends Component {
    render() {
        const { match } = this.props;
        return (
            <main>
                <SubHeader menu={[]} bg={"https://images.pexels.com/photos/1629236/pexels-photo-1629236.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"} location={match.path}/>
                <Switch >
                    <Route exact path="/camps/" component={Camp}/>
                    <Route exact path="/camps/:media/:id/:camp/" component={campMedia}/>
                    <Route exact path="/camps/:page/" component={Camp}/>
                    <Route exact path="/camps/:page/:year/" component={Camp}/>
                    <Route exact path="/camps/:page/:year/:place/" component={Camp}/>
                    <Route exact path="/camps/:page/:year/:place/:category" component={Camp}/>
                    {/* <Route exact path="/camp/camp_details/:slug/" component={campMedia}/> */}
                </Switch>
            </main>
        );
    }
}
