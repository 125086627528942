import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import '../../assets/scss/pages/bootstrap_modal.scss';
import copy from 'copy-to-clipboard';

import { Button,  } from '@material-ui/core';
import { 
    FileCopyOutlined, 
    Twitter, 
    Facebook, 
    Reddit,
    EmailOutlined,
    LinkedIn,
    Telegram,
    WhatsApp
} from '@material-ui/icons';
import { Link } from "react-router-dom";
import Clipboard from "react-clipboard";


class BootstrapModal extends Component {
    copyClipBoard=()=>{
        copy(window.location.href)
    }
    render() {
        const { open, onClose } = this.props
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={onClose}
                className="__bootstrap-modal"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content col-12">
                        <div className="modal-header">
                            <h5 className="modal-title">გაზიარება</h5>
                            <button type="button" className="close" onClick={onClose} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        
                        <div className="modal-body">
                            <div className="icon-container1 d-flex">
                                <a href={`https://twitter.com/intent/tweet?url=${window.location.href}`} target="_blank" className="smd c-twitter" >
                                    <Twitter fontSize="large" />
                                    <p>Twitter</p>
                                </a>
                                <a href={`https://www.facebook.com/sharer.php?u=${window.location.href}`} target="_blank"  className="smd c-facebook">
                                    <Facebook fontSize="large" />
                                    <p>Facebook</p>
                                </a>
                                <a href={`https://www.reddit.com/submit?url=${window.location.href}&title=${document.title}`} target="_blank"  className="smd c-reddit">
                                    <Reddit fontSize="large" />
                                    <p>Reddit</p>
                                </a>
                                <a href={`mailto:?&subject=${document.title}&body=${window.location.href}`} target="_blank"  className="smd c-gmail">
                                    <EmailOutlined fontSize="large" />
                                    <p>Gmail</p>
                                </a>
                            </div>
                            <div className="icon-container2 d-flex">
                                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${document.title}&summary=&source=`} className="smd c-linkedin">
                                    <LinkedIn fontSize="large" />
                                    <p>LinkedIn</p>
                                </a>
                                <a href={`https://www.facebook.com/dialog/send?link=${window.location.href}`} target="_blank"  className="smd c-messenger">
                                    <svg width={34} height={34} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 0c-6.627 0-12 4.975-12 11.111 0 3.497 1.745 6.616 4.472 8.652v4.237l4.086-2.242c1.09.301 2.246.464 3.442.464 6.627 0 12-4.974 12-11.111 0-6.136-5.373-11.111-12-11.111zm1.193 14.963l-3.056-3.259-5.963 3.259 6.559-6.963 3.13 3.259 5.889-3.259-6.559 6.963z"/></svg>                                    <p>Messenger</p>
                                </a>
                                <a href={`https://t.me/share/url?url=${window.location.href}&text=${document.title}&to=`} target="_blank" className="smd c-telegram">
                                    <Telegram fontSize="large" />
                                    <p>Telegram</p>
                                </a>
                                <a href={`https://web.whatsapp.com/send?text=${window.location.href}`} target="_blank"  className="smd c-watsapp">
                                    <WhatsApp fontSize="large" />
                                    <p>WhatsApp</p>
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="row">
                                <input
                                    className="col-10 ur"
                                    type="url"
                                    value={window.location.href}
                                    id="for-copy-clipboard"
                                    aria-describedby="inputGroup-sizing-default"
                                    disabled
                                />
                                <Button variant="contained" onClick={this.copyClipBoard} className="copybtn col-2">
                                    <FileCopyOutlined />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default BootstrapModal