import React, { Component } from 'react'

import axios from "axios"
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css"
// import "@fancyapps/fancybox/dist/jquery.fancybox";
export default class CampMedia extends Component {
    state={
        gallery: [],
        loaded:false,
        scrollPage:1,
        video_gallery: [
            {
                "id":"0",
                "sources" :  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
                "download_url":"https://picsum.photos/id/0/5616/3744"
            },
            {
                "id":"1",
                "sources" :  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
                "download_url":"https://picsum.photos/id/1/5616/3744"
            },
            {
                "id":"10",
                 "sources" :  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
                "download_url":"https://picsum.photos/id/10/2500/1667"
            },
            {
                "id":"100",
                "sources" :  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
                "download_url":"https://picsum.photos/id/100/2500/1656"
            },
            {
                "id":"1000",
                "sources" :  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4",
                "download_url":"https://picsum.photos/id/1000/5626/3635"
            },
            {
                "id":"1001",
                "sources" :  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4",
                "download_url":"https://picsum.photos/id/1001/5616/3744"
            },
            {
                "id":"1002",
                "sources" :  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4",
                "download_url":"https://picsum.photos/id/1002/4312/2868"
            },
            {
                "id":"1003",
                "sources" :  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4",
                "download_url":"https://picsum.photos/id/1003/1181/1772"
            },
            {
                "id":"1004",
                "sources" :  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/SubaruOutbackOnStreetAndDirt.mp4",
                "download_url":"https://picsum.photos/id/1004/5616/3744"
            },
            {
                "id":"1005",
                "sources" :  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4",
                "download_url":"https://picsum.photos/id/1005/5760/3840"
            },
            {
                "id":"1006",
                "sources" :  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/VolkswagenGTIReview.mp4",
                "download_url":"https://picsum.photos/id/1006/3000/2000"
            },
            {
                "id":"1008",
                "sources" :  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4",
                "download_url":"https://picsum.photos/id/1008/5616/3744"
            },
            {
                "id":"1009",
                "sources" :  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WhatCarCanYouGetForAGrand.mp4",
                "download_url":"https://picsum.photos/id/1009/5000/7502"
            },
        ]
    }
    getImages = (page=1,limit=20) => {
        axios.get(`https://picsum.photos/v2/list?page=${page}&limit=${limit}`).then(({data})=>{
            let gallery  = this.state.gallery.concat(data);
            this.setState({gallery, loaded:true})
            
        })
    }
    trackScrolling = () => {
        if (window.scrollY+100 >= window.innerHeight&&this.state.loaded) {
            this.setState({loaded:false})
            this.getImages(this.state.scrollPage+1, 10)
            this.setState({scrollPage:this.state.scrollPage+1})
        }
    }
    componentWillMount(){
        this.getImages();
        document.addEventListener('scroll', this.trackScrolling);
      
    }
          
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }
    render() {
        return (
            <section id="camp-media" className="container">
                <div className="camp-hero" >
                    <img src="https://scontent.ftbs5-2.fna.fbcdn.net/v/t1.0-9/39211032_2369699019721888_2455055537490886656_o.jpg?_nc_cat=101&_nc_sid=dd9801&_nc_eui2=AeF0sRxAWaRSGYPWcVsiaewBXNmcRXBg1wJc2ZxFcGDXAvyKorqy_rwzKAfp2lxZRgJmsZwAHUPVXsZMh0jiGaug&_nc_ohc=MTBm55e5FxIAX96LkgW&_nc_ht=scontent.ftbs5-2.fna&oh=0ad9ef5f3fd53d3e00f36ad0a2e6dfc5&oe=5EE573AD" alt="" />
                    <div className="center-camp-title">
                        <h1 className="camp-hero-title">ჩემი ტრანსფორამცია</h1>
                        <h3 className="camp-hero-subtitle">ბაზალეთი - 2018<br/></h3>    
                    </div>
                </div>
                <div className="row camp-photo-gallery">
                    {
                    this.state.video_gallery.map(({sources,download_url},i)=>{
                        return(
                            <div className="camp-thumbmails col-3" key={i}>
                                <a data-fancybox="gallery" className="camp-gallery-item" href={sources}>
                                    <img src={download_url}/>
                                </a>
                            </div>
                            
                
                        )
                    })
                    }

                    <div id="loading-page" className="camp-gallery-loader">
                        <p className="loader">Loading...</p>
                    </div>
                </div>

                 
            </section>
        )
    }
}
