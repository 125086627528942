import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
          <footer id="footer">
            <div>
              <div className="footer-line" ></div>
              <nav className="footer-nav">
                <ul className="footer-social">
                  <li>
                    <a href="data.facebook_link">
                      <img src={require("../../assets/svg/facebook.svg")} alt="facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="data.youtube_link">
                      <img src={require("../../assets/svg/youtube.svg")} alt="youtube" />
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="footer-bottom" />
            </div>
          </footer>
        );
    }
}

export default Footer;
