import React, { Component } from "react";
import { Link } from "react-router-dom";

class Banners extends Component {
  state = {
    banners: [
      {
        ID: 0,
        img: {
          src:
            "https://images.pexels.com/photos/2019331/pexels-photo-2019331.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          alt: "test",
        },
        title: "bannerName",
        route: "/main_worship",
      },
      {
        ID: 1,
        img: {
          src:
            "https://images.pexels.com/photos/1157557/pexels-photo-1157557.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          alt: "test",
        },
        title: "bannerName",
        route: "/youth",
      },
      {
        ID: 2,
        img: {
          src:
            "https://images.unsplash.com/photo-1530688957198-8570b1819eeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1947&q=80",
          alt: "test",
        },
        title: "bannerName",
        route: "/pray",
      },
      {
        ID: 3,
        img: {
          src:
            "https://images.squarespace-cdn.com/content/v1/52033caae4b0c1bcad8198e1/1538499294522-NQ6EH1539XMOIJSBHP9N/ke17ZwdGBToddI8pDm48kIcBVp0Kco9ps-3Nmj_IAI8UqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYy7Mythp_T-mtop-vrsUOmeInPi9iDjx9w8K4ZfjXt2dkaEhd3cM48eexz_dmzyF4sPE4SrXj68-5DJ5i26zb6wm7cT0R_dexc_UL_zbpz6JQ/ss-homepage.jpg?format=1500w",
          alt: "test",
        },
        title: "bannerName",
        route: "/sunday_school",
      },
      {
        ID: 4,
        img: {
          src:
            "https://images.pexels.com/photos/1687845/pexels-photo-1687845.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          alt: "test",
        },
        title: "bannerName",
        route: "/camps",
      },
      {
        ID: 5,
        img: {
          src:
            "https://scontent.ftbs5-2.fna.fbcdn.net/v/t1.0-9/62222470_2460184484026923_6018294288973037568_o.jpg?_nc_cat=110&_nc_sid=cdbe9c&_nc_ohc=hlRk7NUc7R4AX_SwcRO&_nc_ht=scontent.ftbs5-2.fna&oh=548dce0ed7fea3179fcd3dacb4389f5b&oe=5F56EF95",
          alt: "test",
        },
        title: "bannerName",
        route: "/music",
      },
    ],
  };
  render() {
    return (
      <section id="Banner">
        <div className="bannerContainer container row ">
          {this.state.banners.map((banner, i) => {
            return (
              <Link
                to={banner.route}
                className={`banner-object animate__fadeInUp custom__delay-${
                  i + 2
                } animate__animated`}
                key={banner.ID}
              >
                <img src={banner.img.src} alt={banner.img.alt} />
                <div className="hoverbg">
                  <div className="hover-bg-line I"></div>
                  <div className="hover-bg-line II"></div>
                  <div className="hover-bg-line III"></div>
                  <div className="hover-bg-line IV"></div>
                </div>
                {/* <img :src="{require('../../assets/img/'" + banner.img.src)} title="banner.title" alt="banner.img.alt" /> */}
              </Link>
            );
          })}
        </div>
      </section>
    );
  }
}

export default Banners;
