import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

class Banner extends Component {
  render() {
    return (
      <section id="hero-banner" style={{backgroundImage: `url('https://scontent.ftbs5-1.fna.fbcdn.net/v/t1.0-9/33616555_1888221051223272_5232143900314959872_o.jpg?_nc_cat=102&_nc_sid=e007fa&_nc_ohc=f5BqwIeqcooAX_pcgcR&_nc_ht=scontent.ftbs5-1.fna&oh=a31b5cc36459548565d60c3ebac0d5e3&oe=5F2B1B18')`}}>
            <div className="bg-filter" style={{backgroundImage: `url('${require("../../assets/img/bg.png")}')`}}></div>
            <article className="hero-world container animate__animated custom__delay-10 animate__fadeIn">
              <h1 >
                იცხოვრე <strong className="animate__animated custom__delay-5 ">რწმენით,</strong><br/>
                შეიცანი <strong className="animate__animated custom__delay-10 ">სიყვარულით,</strong><br/>
                იყავი ხმა <strong className="animate__animated custom__delay-15 ">იმედის.</strong><br/>
              </h1>
            </article>
            <div className="join-church">
                <Link to="/main_worship" className="animate__animated custom__delay-5 animate__fadeIn">
                    <Button size="large" variant="contained">
                        <PlayCircleOutlineIcon/>
                        ქადაგებები
                    </Button>
                </Link>
                <Link to="/" className="animate__animated custom__delay-5 animate__fadeIn">
                    <Button size="large" variant="contained">
                        შემოგვიერთდით
                    </Button>
                </Link>
              </div>
            <div className="banner-square"></div>
      </section>
    )
  }
}

export default Banner; 