import React, { PureComponent } from "react";
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import { Schedule } from "@material-ui/icons";
import axios from "axios";
import DateFormatter from "../../functions/date-formatter";

class Events extends PureComponent {
  state = {
    events: [],
  };
  openEven(href) {
    window.location.href = href;
  }
  getEvents = () => {
    axios
      .get("http://q.qristianinews.ge/search/all/events")
      .then((res) => this.setState({ events: res.data.events }));
  };
  componentDidMount() {
    this.getEvents();
  }
  render() {
    return (
      <section id="evnets" className="container">
        <h2 className="event-heading">ღონისძიებები</h2>
        {this.state.events.length &&
          this.state.events.map(
            ({
              id,
              slug,
              AstartDate,
              AendDate,
              AstartTime,
              AendTime,
              name,
              imgurl,
            }) => (
              <Card className="event-card " key={id}>
                <CardMedia
                  className="event-image"
                  image={imgurl}
                  title="Live from space album cover"
                />
                <CardContent className="event-content">
                  <div>
                    <Typography
                      className="event-title"
                      component="h5"
                      variant="h5"
                    >
                      {name}
                    </Typography>
                    <div className="d-flex align-items-center">
                      <Schedule className="mr-1 event-time-icon" />
                      <Typography
                        className="event-time"
                        variant="subtitle1"
                        color="textSecondary"
                      >
                        {AstartTime} - {AendTime}
                      </Typography>
                    </div>
                  </div>
                  <div className="event-date mr-2">
                    <p className="e-day">
                      {DateFormatter(AstartDate, false, true, false)}
                      {AstartDate.slice(3, 5) !== AendDate.slice(3, 5) &&
                        " - " + DateFormatter(AendDate, false, true, false)}
                    </p>
                    <Typography
                      className="e-month"
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      {DateFormatter(AstartDate, true, false, false)}
                      {AstartDate !== AendDate &&
                        " - " + DateFormatter(AendDate, true, false, false)}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            )
          )}
      </section>
    );
  }
}

export default Events;
