import React, { Component } from 'react';

// ICONS
import PersonIcon from '@material-ui/icons/Person';
import EventIcon from '@material-ui/icons/Event';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import PauseCircleFilledOutlinedIcon from '@material-ui/icons/PauseCircleFilledRounded';

// LIBRARIES
import Axios from 'axios';
import AudioPlayer from '../partials/audioPlayer';
import "react-h5-audio-player/src/styles.scss"

// FUNCTIONS
import Transcript from '../../functions/swap-letters-en-ka'
import dateFormatter from '../../functions/date-formatter';

import { TextField, MenuItem, InputLabel, FormControl, Select } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';


const retro = createMuiTheme({
    palette: {
        primary: {
            light: 'rgb(102, 101, 71)',
            main: "rgb(102, 101, 71)",
        },
    },
});
export class audioArchive extends Component {
    state = {
        audioPreaches: [],

        current_preacher: this.props.match.params.preacher !== undefined ? parseInt(this.props.match.params.preacher) : 0,
        current_year: this.props.match.params.year !== undefined ? parseInt(this.props.match.params.year) : 0,
        slug: this.props.match.params.slug,

        searchWord: "",
        preachers: [],
        years: [],
        sound_url: '',
        preachErr: false,
        activePage: 1,
        preachIndex: 0
    }

    search = input => {
        this.setState({ searchWord: input.target.value })
        clearTimeout(this.state.timeout);
        this.setState({ timeout: setTimeout(this.GetPreaches, 1000) });
    }

    getAudioPreaches = (preacher = this.state.current_preacher, part = 1, year = this.state.current_year) => {
        const { searchWord, current_year, audioPreaches } = this.state;
        Axios.get(`https://q.qristianinews.ge/search/all/audio/${preacher}/${Boolean(searchWord.trim()) ? searchWord.trim() : "0"}/${current_year}/1`)
            .then(res => {
                this.setState({
                    audioPreaches: res.data.qadagebebi,
                    preachers: res.data.mqadageblebi,
                    years: res.data.years,
                    preachIndex: 0
                })
                this.props.history.push(`/main_worship/archive/audio/${preacher}/${year}`)

            }).catch(err => {

            })
    }
    previousPreach = () => {
        console.log("previous")
        let { preachIndex } = this.state;
        if (preachIndex > 0) {
            preachIndex--
            this.setState({ preachIndex })
        }
    }
    nextPreach = () => {
        console.log("next")
        let { preachIndex, audioPreaches } = this.state;
        if (preachIndex < audioPreaches.length) {
            preachIndex++
            this.setState({ preachIndex })
        }
    }
    preachStart = () => {

    }
    handleYear = preach_year => {
        let year = preach_year.target.value;
        this.setState({
            current_year: parseInt(year),
            activePage: 1
        });
        this.getAudioPreaches(this.state.current_preacher, 1, year)
    }

    handlePreacher = preacher_id => {
        let preacher = preacher_id.target.value;
        this.setState({
            current_preacher: parseInt(preacher),
            activePage: 1
        });
        this.getAudioPreaches(preacher, 1, this.state.current_year)
    }

    handlePreach = preach_id => {
        this.props.history.push(`/main_worship/archive/audio/${this.state.current_preacher}/${this.state.current_year}/${this.state.audioPreaches[preach_id].slug}`)
        this.setState({ preachIndex: preach_id })
    }
    preachStart() {

    }
    componentWillMount() {
        this.getAudioPreaches()
    }



    render() {
        let { audioPreaches, current_preacher, preachers, preachIndex, years, searchWord, current_year } = this.state;
        let { previousPreach, nextPreach, handlePreach, handlePreacher, search, preachStart, handleYear } = this;
        return (
            <section id="audio-archive" className="row container mx-auto ">
                <div className="audio-content">
                    <div className="audio-head">
                        <div className="waves">
                            <img src="https://embody.audio/wp-content/uploads/2018/12/giphy_wave.gif" alt="audio waves" />
                        </div>
                        <div className="preach-meta">
                            <h2 className="meta-title">{Boolean(audioPreaches[current_preacher]) ? Transcript(audioPreaches[current_preacher].name) : ""}</h2>
                            <div className="preacher-detail">
                                <PersonIcon />
                                <p className="detail-value">{Boolean(audioPreaches[current_preacher]) ? audioPreaches[current_preacher].preacher_id : ""}</p>
                            </div>
                            <div className="preacher-detail">
                                <EventIcon />
                                <p className="detail-value">{Boolean(audioPreaches[current_preacher]) ? dateFormatter(audioPreaches[current_preacher].sDate) : ""}</p>
                            </div>
                            <div className="filter">
                                <div className="filter-container container">
                                    <div className="preach-calendar">
                                        <ThemeProvider theme={retro}>
                                            <FormControl >
                                                <InputLabel >მქადაგებელი</InputLabel>
                                                <Select
                                                    value={current_preacher}
                                                    onChange={value => { handlePreacher(value) }}

                                                >
                                                    <MenuItem value={0} >ყველა</MenuItem>
                                                    {
                                                        preachers.map(preacher => {
                                                            return (
                                                                <MenuItem value={preacher.id} key={preacher.id} >{preacher.name}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                            <FormControl >
                                                <InputLabel >წელი</InputLabel>
                                                <Select
                                                    value={current_year}
                                                    onChange={value => { handleYear(value) }}

                                                >
                                                    <MenuItem value={'0'} >ყველა</MenuItem>
                                                    {
                                                        years.map(year => {
                                                            return (
                                                                <MenuItem value={year} key={year} >{year}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                            <FormControl >
                                                <TextField id="standard-search" value={searchWord} onChange={search} label="ძებნა" type="search" />
                                            </FormControl>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="audio-body">
                        {
                            audioPreaches.map((preach, i) => {
                                return (
                                    <div className={i === current_preacher ? "audio-object active" : "audio-object"} key={i} onClick={() => { handlePreach(i) }}>
                                        <div className="audio-left-side">
                                            {
                                                i === current_preacher ?
                                                    <PauseCircleFilledOutlinedIcon />
                                                    :
                                                    <PlayCircleFilledWhiteOutlinedIcon />

                                            }
                                            <p className="audio-title">{Transcript(preach.name)}</p>
                                        </div>
                                        <div className="audio-right-side">{dateFormatter(preach.sDate)}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="audio-player">
                        {
                            audioPreaches.length !== 0 && current_preacher !== audioPreaches.length ?
                                <AudioPlayer
                                    retro={true}
                                    autoplay={true}
                                    onNext={nextPreach}
                                    onPrev={previousPreach}
                                    onStart={preachStart}
                                    onFinish={nextPreach}
                                    src={audioPreaches[preachIndex].fileurl}
                                    title={audioPreaches[preachIndex].name}
                                />
                                :
                                ""
                        }
                    </div>
                </div>
            </section>
        );
    }
}

export default audioArchive;
