import React, { Component } from 'react'
import { TextField, FormControl } from '@material-ui/core';
import Pagination from "material-ui-flat-pagination";
import { Link } from "react-router-dom";

export default class Activities extends Component {
    state = {
        activePage: this.props.match.params.page ? parseInt(this.props.match.params.page) : 1,
        pageCount: 12
    }
    handlePagination = (_el, page_offset) => {
        console.log(page_offset)
        let activePage = (page_offset / 12) + 1;
        this.setState({ activePage })
        this.props.history.push(`/youth/activities/${activePage}/`)
    }
    render() {
        const { activePage, pageCount } = this.state;
        return (
            <section >
                <div className="filter">
                    <div className="filter-container container">
                        <div className="preach-calendar">
                            <FormControl >
                                <TextField id="standard-search" value=" " label="ძებნა" type="search" />
                            </FormControl>
                        </div>
                    </div>
                </div>

                <div className="row container mx-auto p-0" id="activities">
                    <article className="col-4">
                        <Link to="/sunday_school/activitie/aq_iqneba_slagi/" className="activity">
                            <figure className="activities-image">
                                <img src="https://scontent.ftbs5-2.fna.fbcdn.net/v/t1.0-9/45878956_2542354025789719_5179656530919686144_n.jpg?_nc_cat=103&_nc_sid=110474&_nc_ohc=OkAbDitbcK4AX-25n4G&_nc_ht=scontent.ftbs5-2.fna&oh=ff1c3af60a0efa78b1af466d7082ddaf&oe=5EEEFE5B" alt="" />
                            </figure>
                            <div className="activities-title">
                                <h2>სინათლის თაობის აქცია სუიციდის წინააღმდეგ თბილისის ქუჩებში</h2>
                            </div>
                        </Link>
                    </article>
                    <div className="pag-sec">
                        <Pagination
                            limit={12}
                            offset={(activePage - 1) * 12}
                            previousPageLabel="&#10094; წინა"
                            nextPageLabel="შემდეგი &#10095;"
                            total={pageCount * 12}
                            onClick={this.handlePagination}
                        />
                    </div>
                </div>
            </section>
        )
    }
}
