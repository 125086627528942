import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Header extends Component {
    render() {
        return (
          <header
            id="header"
            className="row align-items-center no-gutters"
          >
            <nav className="container nav-inner mx-auto align-items-center justify-content-between row">
                <div id="logo">
                    <Link to="/">
                        <img src={require("../../assets/svg/logo.svg")} alt="qristiani.ge logo" />
                    </Link >
              </div>
              <ul className="main-nav justify-content-between d-flex">
                <li>
                  <Link to="/"> სიახლეები </Link >
                </li>
                <li>
                  <Link to="/about_us"> ჩვენს შესახებ </Link >
                </li>
                <li>
                    <Link to="/"> კონტაქტი </Link>
                </li>
              </ul>
            </nav>
          </header>
        );
    }
}

export default Header;
