import React, { Component } from "react";

// ICONS
import PersonIcon from "@material-ui/icons/Person";
import EventIcon from "@material-ui/icons/Event";
import PlayCircleFilledWhiteOutlinedIcon from "@material-ui/icons/PlayCircleFilledWhiteOutlined";
import PauseCircleFilledOutlinedIcon from "@material-ui/icons/PauseCircleFilledRounded";

// LIBRARIES
import Axios from "axios";
import AudioPlayer from "../partials/audioPlayer";
import "react-h5-audio-player/src/styles.scss";

// FUNCTIONS
import Transcript from "../../functions/swap-letters-en-ka";
import dateFormatter from "../../functions/date-formatter";
import SubHeader from "../partials/subheader";

import {
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const retro = createMuiTheme({
  palette: {
    primary: {
      light: "rgb(102, 101, 71)",
      main: "rgb(102, 101, 71)",
    },
  },
});
export class audioArchive extends Component {
  state = {
    audioPreaches: [],
    current_preach: 0,
    searchWord: "",
    preachers: [],
    pageCount: [],
    preacher:
      this.props.match.params.preacher !== undefined
        ? parseInt(this.props.match.params.preacher)
        : 0,
    slug: this.props.match.params.slug,
  };

  search = (input) => {
    this.setState({ searchWord: input.target.value });
    clearTimeout(this.state.timeout);
    this.setState({ timeout: setTimeout(this.GetPreaches, 1000) });
  };

  getAudioPreaches = (preaceher = 0, part = 0) => {
    Axios.get("https://api.jsonbin.io/b/5e384dae593fd7418575783c/latest", {
      headers: {
        "secret-key":
          "$2a$10$zvusTdO1ZbEMAbm9TUg6W.gGXH8.doPoZ9sHn8Ct8TmY61CSQGxB2",
      },
    }).then((res) => {
      this.setState({ audioPreaches: res.data });
    });
  };
  previousPreach = () => {
    console.log("previous");
    let { current_preach } = this.state;
    if (current_preach > 0) {
      current_preach--;
      this.setState({ current_preach });
    }
  };
  nextPreach = () => {
    console.log("next");
    let { current_preach, audioPreaches } = this.state;
    if (current_preach < audioPreaches.length) {
      current_preach++;
      this.setState({ current_preach });
    }
  };
  preachStart = () => {};
  handlePreach = (preach_id) => {
    this.setState({ current_preach: preach_id });
  };
  preachStart() {}
  componentWillMount() {
    this.getAudioPreaches();
  }

  render() {
    let { audioPreaches, current_preach, preachers, searchWord } = this.state;
    let {
      previousPreach,
      nextPreach,
      handlePreach,
      search,
      preachStart,
    } = this;
    return (
      <React.Fragment>
        <SubHeader
          menu={[]}
          bg={
            "https://images.pexels.com/photos/1629236/pexels-photo-1629236.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
          }
          location={this.props.match.path}
        />
        <section id="audio-archive" className="row container mx-auto ">
          <div className="audio-content">
            <div className="audio-head">
              <div className="waves">
                <img
                  src="https://embody.audio/wp-content/uploads/2018/12/giphy_wave.gif"
                  alt="audio waves"
                />
              </div>
              <div className="preach-meta">
                <h2 className="meta-title">
                  {audioPreaches.length !== 0 &&
                  current_preach !== audioPreaches.length
                    ? Transcript(audioPreaches[current_preach].name)
                    : "-"}
                </h2>

                <div className="preacher-detail">
                  <PersonIcon />
                  <p className="detail-value">
                    {audioPreaches.length !== 0 &&
                    current_preach !== audioPreaches.length
                      ? audioPreaches[current_preach].preacher_id
                      : "-"}
                  </p>
                </div>

                <div className="filter">
                  <div className="filter-container container">
                    <div className="preach-calendar">
                      <ThemeProvider>
                        <FormControl>
                          <InputLabel>მქადაგებელი</InputLabel>
                          <Select
                            value={this.state.preacher}
                            onChange={(value) => {
                              this.handlePreacher(value);
                            }}
                          >
                            <MenuItem value={0}>ყველა</MenuItem>
                            {this.state.preachers.map((preacher) => {
                              return (
                                <MenuItem value={preacher.id} key={preacher.id}>
                                  {preacher.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <FormControl>
                          <TextField
                            id="standard-search"
                            value={searchWord}
                            onChange={search}
                            label="ძებნა"
                            type="search"
                          />
                        </FormControl>
                      </ThemeProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="audio-body">
              {audioPreaches.map((preach, i) => {
                return (
                  <div
                    className={
                      i === current_preach
                        ? "audio-object active"
                        : "audio-object"
                    }
                    key={i}
                    onClick={() => {
                      handlePreach(i);
                    }}
                  >
                    <div className="audio-left-side">
                      {i === current_preach ? (
                        <PauseCircleFilledOutlinedIcon />
                      ) : (
                        <PlayCircleFilledWhiteOutlinedIcon />
                      )}
                      <p className="audio-title">{Transcript(preach.name)}</p>
                    </div>
                    <div className="audio-right-side">
                      {dateFormatter(preach.date)}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="audio-player">
              {audioPreaches.length !== 0 &&
              current_preach !== audioPreaches.length ? (
                <AudioPlayer
                  autoplay={true}
                  onNext={nextPreach}
                  onPrev={previousPreach}
                  onStart={preachStart}
                  onFinish={nextPreach}
                  src={require("../../assets/audio/preaches/" +
                    audioPreaches[current_preach].src)}
                  title={audioPreaches[current_preach].name}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default audioArchive;
