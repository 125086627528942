import React, { Component } from "react";
import ReactPlayer from "react-player";
import PauseOutlinedIcon from "@material-ui/icons/PauseOutlined";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import Duration from "../../functions/Duration";

export default class App extends Component {
  state = {
    url: "https://youtu.be/" + this.props.uri,
    pip: false,
    playing: true,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    showController: false,
    bible_parts: [
      {
        close_part: false,
        name: "იოანე. 19:11",
        text: `მიუგო იესომ: არავითარი ხელმწიფება არ გექნებოდა ჩემზე, 
              ზეციდან რომ არ მოგცემოდა. ამიტომ მას, ვინც ჩემი თავი გადმოგცა, 
              უფრო დიდი ცოდვა აქვს”.`,
        link:
          "http://holybible.ge/bible/georgian/book-1-43-19-11-m-geo-m-geo--0",
        time: {
          start: 1120,
          end: 1135,
        },
      },
      {
        close_part: false,
        name: "maTe. 5:11",
        text: `ნეტარ ხართ თქვენ, როცა გაგლანძღავენ, გაგდევნიან და ყოველგვარ ბოროტებას დაგაბრალებენ ჩემ გამო.`,
        link:
          "http://holybible.ge/bible/georgian/book-1-40-5-11-m-geo-m-geo--0",
        time: {
          start: 1920,
          end: 3030,
        },
      },
    ],
  };

  handleDuration = (duration) => {
    console.log("onDuration", duration);
    this.setState({ duration });
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  handleVolumeChange = (e) => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  handleToggleMuted = () => {
    if (this.state.volume === 0) {
      this.handleVolumeChange({ target: { value: "0.1" } });
    } else {
      this.setState({ muted: !this.state.muted });
    }
  };

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  handlePlay = () => {
    console.log("onPlay");
    this.setState({ playing: true });
  };

  handlePause = () => {
    console.log("onPause");
    this.setState({ playing: false });
  };

  handleProgress = (state) => {
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.state.player.seekTo(parseFloat(e.target.value));
  };

  handleSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  handleDuration = (duration) => {
    console.log("onDuration", duration);
    this.setState({ duration });
  };

  ContorllerHover = () => {
    this.setState({ showController: true });
    setTimeout(() => {
      this.setState({ showController: false });
    }, 10000);
  };

  ref = (player) => {
    this.setState({ player });
  };

  render() {
    const {
      url,
      playing,
      volume,
      muted,
      played,
      loaded,
      duration,
      bible_parts,
    } = this.state;

    return (
      <div className="video-player">
        {bible_parts.map((bible_part, key) => {
          return bible_part.time.start < Duration(duration * played) &&
            bible_part.time.end >= Duration(duration * played) &&
            !bible_part.close_part ? (
            <div
              key={key}
              className="bible-part"
              onClick={() => {
                bible_part.close_part = true;
              }}
            >
              <CloseOutlinedIcon className="close_part" />
              <a href={bible_part.link}>{bible_part.name}</a>
              <p>{bible_part.text}</p>
            </div>
          ) : (
            ""
          );
        })}

        <ReactPlayer
          ref={this.ref}
          url={url}
          autoplay
          playing={playing}
          muted={muted}
          width="100%"
          volume={volume}
          height={window.innerWidth * 0.3271}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
          onPlay={this.handlePlay}
          onPause={this.handlePause}
          youtubeConfig={{
            playerVars: {
              showinfo: 0,
              autoplay: 1,
            },
          }}
        />
        {loaded === 0 ? (
          <div className="loading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          ""
        )}

        <div id="player-controller">
          <button className="handle-play" onClick={this.handlePlayPause}>
            {playing ? <PauseOutlinedIcon /> : <PlayCircleFilledWhiteIcon />}
          </button>
          <div className="player-progress">
            <progress className="seek loaded" value={loaded} max={1}></progress>
            <progress
              className="seek"
              value={played}
              min={0}
              max={1}
            ></progress>
            <input
              className="range-slider"
              type="range"
              min={0}
              max={1}
              step={0.003}
              value={played}
              onMouseDown={this.handleSeekMouseDown}
              onChange={this.handleSeekChange}
              onMouseUp={this.handleSeekMouseUp}
            />
          </div>
          <div className="pl-controller">
            <p className="video-duration">
              <Duration seconds={duration * played} />/
              <Duration seconds={duration} />
            </p>
            <div className="sound-controller">
              <button className="handle-sound" onClick={this.handleToggleMuted}>
                {muted || volume === 0 ? <VolumeOffIcon /> : <VolumeUpIcon />}
              </button>
              <progress
                className="volume-progress"
                value={volume}
                max={1}
              ></progress>
              <input
                type="range"
                className="volume-progress-slider"
                min={0}
                max={1}
                step={0.05}
                value={volume}
                onChange={this.handleVolumeChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
