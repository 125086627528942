import React, { Component } from 'react';

import Axios from 'axios';
import dateFormatter from '../../functions/date-formatter';
import Pagination from "material-ui-flat-pagination";
import {FormHelperText, FormControl, Card, TextField, Modal, Avatar, Chip, LinearProgress} from '@material-ui/core';

import '../../assets/scss/pages/pray.scss'

class pray extends Component {
    state = {
        needs:[],
        pageCount:1,
        activePage: 1,
        add_modal:false,
        neederText:"",
        neederEmail:"",
        neederName:"",
        textEmpty: false,
        startSending: false,
        ver_need:false,
        send_error: false,
        inProgress: false,
    }

    get_needs = ()=>{
        this.setState({inProgress: true})
        Axios.get('https://api.jsonbin.io/b/5e5fb08ebaf60366f0e2ab3a/latest',{
            headers: {
                "secret-key": "$2a$10$zvusTdO1ZbEMAbm9TUg6W.gGXH8.doPoZ9sHn8Ct8TmY61CSQGxB2"
            }
        }).then(res=>{
            this.setState({
                needs:res.data,
                inProgress: false,
                pageCount: 25
            })
        })
        

    }
    onPray = pray_id=>{
        Axios.post("test", {
            pray_id
        }).then(()=>{
            
        }).catch(()=>{

        })
    }

    sendNeed = ()=>{
        const {neederText, neederEmail, neederName} = this.state;

        if(neederText.trim().length !== 0){
            this.setState({
                textEmpty:false,
                startSending:true
            })

            Axios.post("test", {
                neederText, 
                neederEmail, 
                neederName
            }).then(()=>{
                this.setState({
                    ver_need:true,
                    startSending: false,
                    add_modal:false,
                })
            }).catch(()=>{
                this.setState({
                    send_error:true,
                    startSending: false
                })
            })
        } else{
            this.setState({textEmpty:true})
        }
        
    }
    handlePageClick = offset=>{
        let activePage = (offset/12)+1;
        this.setState({activePage});
        this.props.history.push(`/pray/${activePage}` );
        this.get_needs()
    }
    inputValue = (event,input) =>{
        event.persist();
        const text = event.target.value;
        switch (input) {
            case "name":
                this.setState({neederName:text});
                break;
            case "email":
                this.setState({neederEmail:text});
                break;

            case "text":
                if(text.length <= 500){
                    this.setState({neederText:text})
                }
                break;    
            default:
                break;
        }
        
    }
    
    componentWillMount(){
        if(this.props.match.params.page===undefined){
            this.props.history.push("/pray/1")
        }
        this.get_needs()
    }

    render() {
        const {needs,activePage,pageCount, send_error, inProgress, add_modal, neederText, neederEmail, neederName, textEmpty, startSending, ver_need} = this.state;
        const { onPray, inputValue,sendNeed, handlePageClick} = this;
        return (
            <main id="pray">
                {inProgress ? <LinearProgress /> : ""}
                <header className="pray-banner"style={{backgroundImage:`url(${require('../../assets/img/cloud.jpg')})`}}></header>
                <section className="pray-sec container">
                    <div className="pray-top">
                        <img className="bible-qoute animate__fadeIn animate__animated" src={require("../../assets/svg/worship_2.svg")} />
                        
                        {/* <button className="add-need">saWiroebebis damateba</button> */}
                        <button className="add-need animate__fadeIn animate__animated" onClick={()=>{this.setState({add_modal:true})}}>საჭიროების დამატება</button>
                    </div>
                    <div className="needs">
                        {
                            needs.map((need,i)=>{
                                return(
                                <div className={`need-obj animate__fadeInUp custom__delay-${i+2} animate__animated`} key={need.id}>
                                    <div className="need-data">
                                        <div>
                                            <p className="needer-name">{need.name}</p>
                                            <p className="need-cd">{dateFormatter(need.sDate)}</p>
                                        </div>
                                    </div>
                                    <div className="need-text">
                                        <p>{need.text}</p>
                                    </div>
                                    <Chip
                                        avatar={<Avatar>{need.pray_len}</Avatar>}
                                        label="ვილოცე"
                                        className={need.prayed? "pray-btn prayed" : "pray-btn"}
                                        clickable
                                        variant="outlined"
                                        onClick={()=>{onPray(need.id)}}
                                    />
                                </div>)
                            })
                        }
                    </div>
                    <div className="pag-sec">
                        <Pagination
                            limit={12}
                            offset={(activePage-1)*12}
                            previousPageLabel="&#10094; წინა"
                            nextPageLabel="შემდეგი &#10095;"
                            total={pageCount*12}
                            onClick={(e, offset) => this.handlePageClick(offset)}
                        />
                    </div>
                </section>
                <Modal open={ver_need} className="animate__fadeIn animate__animated" onClose={()=>{this.setState({ver_need:false})}}>
                    <Card className="add-needs">
                        <p className="need-verifed">
                            თქვენი საჭიროება მიღევულია და მოდერაციის შემდეგ დაიდება სატესტოა არ მკითხოთ ესე რატო წერიაო
                        </p>
                    </Card>
                </Modal>
                <Modal open={add_modal} className="animate__fadeIn animate__animated" onClose={()=>{this.setState({add_modal:false})}}>
                    <Card className="add-needs">
                        <form className="needs-form" >
                            <h2>საჭიროების დამატება</h2>
                            <FormControl className="needs-field needer-name">
                                <TextField 
                                    value={neederName} 
                                    onChange={text=>{inputValue(text, "name")}} 
                                    label="სახელი" 
                                    variant="outlined" 
                                />
                                <FormHelperText >არა სავალდებულო</FormHelperText>
                            </FormControl>
                            <FormControl className="needs-field needer-mail">
                                <TextField 
                                    value={neederEmail} 
                                    onChange={text=>{inputValue(text, "email")}} 
                                    label="ელ.ფოსტა ან ნომერი" 
                                    variant="outlined"
                                />
                                <FormHelperText >არა სავალდებულო</FormHelperText>
                            </FormControl>
                            <FormControl className="needs-field needer-text">
                                <TextField
                                    value={neederText}
                                    onChange={text=>{inputValue(text, "text")}}
                                    label="ტექსტი" 
                                    variant="outlined" 
                                    multiline 
                                    rows={5} 
                                    required={true} 
                                />
                                {textEmpty ? <FormHelperText error >ტექსტი ცარიელია</FormHelperText> : ""}
                            </FormControl>
                            <button className="send-need" disabled={startSending} onClick={sendNeed} type="button">დამატება</button>
                            {send_error ? <FormHelperText error className="needs-error">დაფიქსირდა შეცდომა</FormHelperText> : ""}

                        </form>
                    </Card>
                </Modal>
            </main>
        )
    }
}

export default pray;