import React, { Component } from "react";
import axios from "axios";

class Education extends Component {
  state = {
    koleji: {
      name: null,
      imgurl: null,
      body: null,
    },
    uni: {
      name: null,
      imgurl: null,
      body: null,
    },
  };
  getEducation = () =>
    axios
      .get(`https://q.qristianinews.ge/search/all/getaboutus/ganatleba`)
      .then((res) => {
        this.setState({
          koleji: res.data.koleji,
          uni: res.data.uni,
        });
      });
  componentDidMount() {
    this.getEducation();
  }
  render() {
    const { koleji, uni } = this.state;
    return (
      <section
        id="education"
        style={{
          backgroundImage: `url('${require("../../assets/img/grad.jpg")}')`,
        }}
      >
        <article className="college-item container">
          <div className="college-header-line ">
            <h2 className="animate__animated animate__fadeIn">{koleji.name}</h2>
          </div>
          <div
            className={`college-desc animate__animated odd animate__fadeInLeft`}
          >
            <div className="col-desc-img">
              <img src={koleji.imgurl} alt="" />
            </div>
            <p className="college-desc-item">{koleji.body}</p>
          </div>
        </article>

        <article className="college-item container">
          <div className="college-header-line ">
            <h2 className="animate__animated animate__fadeIn">{uni.name}</h2>
          </div>
          <div
            className={`college-desc animate__animated even animate__fadeInLeft`}
          >
            <div className="col-desc-img">
              <img src={uni.imgurl} alt="" />
            </div>
            <p className="college-desc-item">{uni.body}</p>
          </div>
        </article>

      </section>
    );
  }
}

export default Education;
