import React, { Component } from "react";
import "./style.scss";

// Icons
// import RepeatIcon from '@material-ui/icons/Repeat';
import PreviousIcon from "@material-ui/icons/SkipPrevious";
import NextIcon from "@material-ui/icons/SkipNext";
import PlayIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import PauseIcon from "@material-ui/icons/PauseCircleOutlineOutlined";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import ShareIcon from "@material-ui/icons/Share";

// Functions
import Duration from "../../../functions/Duration";

import SocialModal from "../bootstrap_modal";

let old_src = "";

class AudioPlayer extends Component {
  state = {
    playing: true,
    played: 0,
    player: "stopped",
    currentTime: 0,
    duration: 0,
    _volume: 0.5,
    openModal: false,
  };
  shareOpen = () => {
    this.setState({ openModal: true });
  };
  shareClose = () => {
    this.setState({ openModal: false });
  };
  handleSeekChange = (e) => {
    this.setState({
      currentTime: parseFloat(e.target.value),
    });
    this.state.player.currentTime = parseFloat(e.target.value);
  };

  Mute = () => {
    this.setState({ _volume: 0 });
    this.state.player.volume = 0;
  };

  SoundOn = () => {
    this.setState({ _volume: 0.75 });
    this.state.player.volume = 0.75;
  };

  handleVolumeChange = (vol) => {
    this.state.player.volume = vol.target.value;
    this.setState({ _volume: parseFloat(vol.target.value) });
  };

  pausePlayer = () => {
    if (this.state.playing) {
      this.state.player.pause();
      this.setState({ playing: false });
    }
  };
  playPlayer = () => {
    if (!this.state.playing) {
      this.state.player.play();
      this.setState({ playing: true });
    }
  };

  componentDidMount() {
    this.state.player.addEventListener("timeupdate", (e) => {
      this.setState({
        currentTime: e.target.currentTime,
        duration: e.target.duration,
      });
    });
    this.state.player.addEventListener("ended", this.props.onFinish);
  }

  componentWillUnmount() {
    this.state.player.removeEventListener("timeupdate", () => {});
    this.state.player.removeEventListener("finish", () => {});
  }

  render() {
    const {
      handleSeekChange,
      pausePlayer,
      playPlayer,
      handleVolumeChange,
      SoundOn,
      Mute,
      shareClose,
      shareOpen,
    } = this;
    const {
      playing,
      player,
      currentTime,
      duration,
      _volume,
      openModal,
    } = this.state;
    const { retro, autoplay, src, onNext, onPrev, title, onStart } = this.props;

    if (old_src !== src && player !== "stopped") {
      player.load();
    }
    old_src = src;

    return (
      <div id="efcg-audio-player" className={retro ? "efcg-retro" : ""}>
        <audio
          ref={(ref) => (this.state.player = ref)}
          onPlay={onStart}
          autoPlay={autoplay}
          preload="auto"
        >
          <source src={src} type="audio/mp3" />
        </audio>

        <div className="efcg-control">
          <button className="efcg-control-icon">
            <PreviousIcon
              onClick={() => {
                onPrev();
              }}
            />
          </button>
          <button className="efcg-control-icon play">
            {playing ? (
              <PauseIcon
                onClick={() => {
                  pausePlayer();
                }}
              />
            ) : (
              <PlayIcon
                onClick={() => {
                  playPlayer();
                }}
              />
            )}
          </button>
          <button className="efcg-control-icon">
            <NextIcon
              onClick={() => {
                onNext();
              }}
            />
          </button>
        </div>
        <div className="efcg-progress-title">
          <h2 className="efcg-title">{title}</h2>
          <div className="efcg-progress">
            {/* <progress className="seek loaded" min={0} value={0.5} max={1}></progress> */}
            <progress
              className="seek"
              min={0}
              value={currentTime}
              max={Boolean(duration) ? duration : 0}
            ></progress>
            <input
              className="range-slider"
              type="range"
              min={0}
              max={Boolean(duration) ? duration : 0}
              step={0.03}
              value={currentTime}
              onChange={handleSeekChange}
            />
          </div>
        </div>
        <Duration className="efcg-duration" seconds={currentTime} />
        <div className="sound-controller">
          <button className="handle-sound" onClick={this.handleToggleMuted}>
            {_volume ? (
              <VolumeUpIcon onClick={Mute} />
            ) : (
              <VolumeOffIcon onClick={SoundOn} />
            )}
          </button>
          <progress
            className="volume-progress"
            value={_volume}
            max={1}
          ></progress>
          <input
            type="range"
            className="volume-progress-slider"
            min={0}
            max={1}
            step={0.05}
            value={_volume}
            onChange={handleVolumeChange}
          />
        </div>
        <ShareIcon onClick={shareOpen} className="efcg-share-icon" />
        <SocialModal open={openModal} onClose={shareClose} />
      </div>
    );
  }
}

export default AudioPlayer;
