import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser';
import { SchoolRounded,  ArrowRight} from '@material-ui/icons';
import axios from "axios";

class C_Leader extends Component {
    state={
        leader: {},
        edu:[
            "შვედეთის „სიცოცხლის სიტყვის“ უნივერსიტეტი",
            "საერთაშორისო ბიბლიური აკადემია ,,ალფა და ომეგა“, აკადემიური დოქტორის ხარისხი",
            "კალიფორნიის ლათინური თეოლოგიური უნივერსიტეტი, თეოლოგიის დოქტორის საპატიო წოდება"
        ]
    }
    
    getLeaders(){
        axios.get(`https://q.qristianinews.ge/search/all/getaboutus/leaders/${this.props.match.params.slug}`).then(res=>{
            this.setState({leader:res.data.leader})
        })
    }
    componentDidMount(){
        this.getLeaders();
    }
  render() {
    const {imgurl,position,name,body} = this.state.leader

    return (
        <section id="custom-leader" className="container">
           <article className="leader-bio">
               <div className="leader-detail">
                   <div className="leader-des-img">
                       <img src={imgurl} alt={name}/>
                    </div>
                    <div className="leader-rank-det">
                        <h2>{name}</h2>
                        <p> {position}</p>
                    </div>
               </div>
               <div className="ck-leader-text">
                    <div className="bio-text">{ReactHtmlParser(body)}</div>
                    <div className="leader-edu-list">
                        <div className="leader-edu-title">
                            <SchoolRounded/> <p>განათლება</p>
                        </div>
                        <ul className="leader-study-list">
                            {
                                this.state.edu.map((title,i)=>{
                                    return(
                                        <li key={i}>
                                            <ArrowRight/>
                                            {title}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
               </div>
           </article>
        </section>
    )
  }
}

export default C_Leader;