export default function (date, d = true, m = true, y = true) {
  if (date !== "") {
    let months = [
      "იან",
      "თებ",
      "მარ",
      "აპრ",
      "მაის",
      "ივნ",
      "ივლ",
      "აგვ",
      "სექ",
      "ოქტ",
      "ნოე",
      "დეკ",
    ];
    let day = date.slice(0, 2);
    let month = months[parseInt(date.slice(3, 5)) - 1];
    let year = date.slice(6, 10);
    return `${d ? day : ""}${m ? " " + month : ""}${y ? "" + year : ""} `;
  }
  return "-";
}
