import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "../assets/scss/style.scss";

// ======= Layout =======
import Footer from "../components/layout/footer";
import Header from "../components/layout/header";

// ======= PAGES =======
import Main from "../components/home/index";
import MainWorship from "../components/main-worship/index";
import Pray from "../components/pray/pray";
import Youth from "../components/youth/index";
import About from "../components/about/index";
import SundaySchool from "../components/sunday-school";
import Camps from "../components/camps";
import Music from "../components/music";

function Routes() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/preach-video/:slug/" component={MainWorship} />
        {/* main_worship */}
        <Route exact path="/main_worship/" component={MainWorship} />
        <Route exact path="/main_worship/preach/" component={MainWorship} />
        <Route exact path="/main_worship/preach/:preacher/" component={MainWorship} />
        <Route exact path="/main_worship/preach/:preacher/:page" component={MainWorship}/>
        <Route exact path="/main_worship/archive/" component={MainWorship} />
        <Route exact path="/main_worship/archive/video/" component={MainWorship} />
        <Route exact path="/main_worship/archive/video/:preacher/:page/" component={MainWorship} />
        <Route exact path="/main_worship/archive/audio/" component={MainWorship} />
        <Route exact path="/main_worship/archive/audio/:preacher/" component={MainWorship} />
        <Route exact path="/main_worship/archive/audio/:preacher/:year/" component={MainWorship} />
        <Route exact path="/main_worship/archive/audio/:preacher/:year/:slug" component={MainWorship} />
        {/* pray */}
        <Route exact path="/pray/:page" component={Pray} />
        <Route exact path="/pray" component={Pray} />
        {/* youth */}
        <Route exact path="/youth/" component={Youth} />
        <Route exact path="/youth/preach/:preacher/:page" component={Youth} />
        <Route exact path="/youth/camp/" component={Youth} />
        <Route exact path="/youth/camp/video/:camp" component={Youth} />
        <Route exact path="/youth/camp/video/:id/:camp" component={Youth} />
        <Route exact path="/youth/camp/photo/:id/:camp" component={Youth} />
        <Route exact path="/youth/camp/photo/:id/" component={Youth} />
        <Route exact path="/youth/camp/:page" component={Youth} />
        <Route exact path="/youth/camp/:page/:year" component={Youth} />
        <Route exact path="/youth/camp/:page/:year/:place" component={Youth} />
        <Route exact path="/youth/activities" component={Youth} />
        <Route exact path="/youth/activities/:page" component={Youth} />
        <Route exact path="/youth/activitie/:slug" component={Youth} />
        <Route exact path="/youth/mettings" component={Youth} />
        <Route exact path="/youth/about_us" component={Youth} />
        {/* about_us */}
        <Route exact path="/about_us" component={About} />
        <Route exact path="/about_us/faith" component={About} />
        <Route exact path="/about_us/leaders" component={About} />
        <Route exact path="/about_us/leaders/:slug" component={About} />
        <Route exact path="/about_us/education" component={About} />
        <Route exact path="/about_us/churches" component={About} />
        {/* sunday_school */}
        <Route exact path="/sunday_school" component={SundaySchool} />
        <Route exact path="/sunday_school/camp/" component={SundaySchool} />
        <Route
          exact
          path="/sunday_school/camp/:media/:camp/"
          component={SundaySchool}
        />
        <Route
          exact
          path="/sunday_school/camp/:page/"
          component={SundaySchool}
        />
        <Route
          exact
          path="/sunday_school/camp/:page/:year/"
          component={SundaySchool}
        />
        <Route
          exact
          path="/sunday_school/camp/:page/:year/:place/"
          component={SundaySchool}
        />
        <Route
          exact
          path="/sunday_school/camp_details/:slug/"
          component={SundaySchool}
        />
        <Route
          exact
          path="/sunday_school/activities/"
          component={SundaySchool}
        />
        <Route
          exact
          path="/sunday_school/activities/:page/"
          component={SundaySchool}
        />
        <Route
          exact
          path="/sunday_school/activitie/:slug/"
          component={SundaySchool}
        />
        {/* Camps  */}
        <Route exact path="/camps" component={Camps} />
        <Route exact path="/camps/:media/:camp" component={Camps} />
        <Route exact path="/camps/:page" component={Camps} />
        <Route exact path="/camps/:page/:year" component={Camps} />
        <Route exact path="/camps/:page/:year/:place" component={Camps} />
        <Route
          exact
          path="/camps/:page/:year/:place/:category"
          component={Camps}
        />
        <Route exact path="/camp/camp_details/:slug" component={Camps} />
        {/* music  */}
        <Route exact path="/music" component={Music} />
        <Route exact path="/music/:band" component={Music} />
        <Route exact path="/music/:band/:album" component={Music} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default Routes;
