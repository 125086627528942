import React, { Component } from 'react'

class churches extends Component {
    render() {
        return (
            <section>
                
            </section>
        )
    }
}

export default churches;
