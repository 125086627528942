import React, { Component } from 'react';
import Banners from './banners';
import HeroBanners from './banner_1';
import Events from './events'
import News from './news';
// import Slider from './slider';
import "animate.css"

class Main extends Component {
    render() {
        return (
            <main>
                <HeroBanners />
                <News/>
                <Banners />
                <Events/>
            </main>
        );
    }
}

export default Main;
