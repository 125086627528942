export default function (word) {
    let geo = ["ა","ბ","გ","დ","ე","ვ","ზ","თ","ი","კ","ლ","მ","ნ","ო","პ","ჟ","რ","ს","ტ","უ","ფ","ქ","ღ","ყ","შ","ჩ","ც","ძ","წ","ჭ","ხ","ჯ","ჰ"]
    let eng = ["a","b","g","d","e","v","z","T","i","k","l","m","n","o","p","J","r","s","t","u","f","q","R","y","S","C","c","Z","w","W","x","j","h"]
    if(word){
        return word.replace(/[ა-ჰ]/gm, letter=>{
            let let_index = geo.indexOf(letter);
            if(let_index !== -1){
                return eng[let_index]
            } 
            return letter
        })
    }
    return ""

}