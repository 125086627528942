import React, { Component } from 'react';
import SubHeader from '../partials/subheader';
import { Route, Switch } from "react-router-dom";

import "../../assets/scss/pages/youth.scss";

import Camp from "./camp";
import campMedia from './campMedia';
import Activities from "./activities";
import Post from "../partials/post";
import About from "../youth/about";


export default class Youth extends Component {
    state = {
        header_menu: [
            {
                name: "მთავარი",
                link:"/sunday_school",
            },
            {
                name: "ბანაკი",
                link: "/sunday_school/camp/1"
            },
            {
                name: "აქტივობები",
                link: "/sunday_school/activities/1"
            }
        ]
    }
    

    render() {
        const { header_menu } = this.state;
        const { match } = this.props;
        return (
            <main>
                <SubHeader menu={header_menu} bg={"https://images.pexels.com/photos/1629236/pexels-photo-1629236.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"} location={match.path}/>
                <Switch >
                    <Route exact path="/sunday_school/" component={About}/>
                    <Route exact path="/sunday_school/camp/" component={Camp}/>
                    <Route exact path="/sunday_school/camp/:media/:camp/" component={campMedia}/>
                    <Route exact path="/sunday_school/camp/:page/" component={Camp}/>
                    <Route exact path="/sunday_school/camp/:page/:year/" component={Camp}/>
                    <Route exact path="/sunday_school/camp/:page/:year/:place/" component={Camp}/>
                    <Route exact path="/sunday_school/camp_details/:slug/" component={Camp}/>
                    <Route exact path="/sunday_school/activities/" component={Activities}/>
                    <Route exact path="/sunday_school/activities/:page/" component={Activities}/>
                    <Route exact path="/sunday_school/activitie/:slug/"  component={Post}/>
                </Switch>
            </main>
        );
    }
}
