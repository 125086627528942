import React, { Component } from 'react';

// Functions
import SwapLetters from '../../functions/swap-letters-en-ka';
import DateFormatter from '../../functions/date-formatter';

// MATERIAL UI
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { TextField, MenuItem, InputLabel, FormControl, Select } from '@material-ui/core';

import 'moment/locale/ka';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import moment from 'moment';
import axios from "axios";
import Pagination from "material-ui-flat-pagination";
import { DateRangePicker } from 'react-dates';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#107DA4',
            main: "#107DA4",
        },
    },
});


export class Preach extends Component {

    state = {
        pageCount: null,
        isArchive: this.props.match.params.preach === "archive" ? true : false,
        preacher: this.props.match.params.preacher !== undefined ? parseInt(this.props.match.params.preacher) : 0,
        activePage: this.props.match.params.page !== undefined ? parseInt(this.props.match.params.page) : 1,
        preaches: [],
        preachers: [],
        loadingStarted: false,
        searchWord: " ",
        focusedInput: null,
        preachError:false,
        startDate: moment([2010, 0, 1]),
        endDate: moment()
    }



    handlePreacher(preacher_id) {
        let preacher = preacher_id.target.value;
        this.setState({
            preacher,
            activePage: 1
        });

        this.props.history.push(this.state.isArchive ? `/main_worship/archive/video/${preacher}/${this.state.activePage}` : `/main_worship/preach/${preacher}/${this.state.activePage}`)
        this.GetPreaches(1, this.state.isArchive ? 7 : 6, preacher)
    }


    handlePageClick= offset=> {

        let activePage = (offset / 24) + 1;
        console.log(activePage)
        this.setState({ activePage });
        this.props.history.push(`/main_worship${this.state.isArchive ? '/archive':""}/preach/${this.state.preacher}/${activePage}`)
        this.GetPreaches(activePage)


    }

    search = input => {
        this.setState({ searchWord: input.target.value })
        clearTimeout(this.state.timeout);
        this.setState({ timeout: setTimeout(this.GetPreaches, 500) });
    }

    GetPreaches = (
        page = this.state.activePage, page_category = this.state.isArchive ? 7 : 6,
        preacher_id = this.state.preacher,
        query = this.state.searchWord
    ) => {
        this.setState({ loadingStarted: true, preachError: false });
        const startDate = this.state.startDate._d;
        const endDate = this.state.endDate._d;
        axios.get(`https://q.qristianinews.ge/search/all/getAllSermon/${page_category}/${preacher_id}/${Boolean(query.trim()) ? query.trim() : "0"}/${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}/${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}/${page}`).then(response => {
            let preaches = response.data.qadagebebi;
            let preachers = response.data.mqadageblebi;
            let pageCount = response.data.pagecount;
            this.setState({
                preaches,
                pageCount,
                preachers,
                loadingStarted: false
            });
        }).catch(err=>{
            this.setState({ 
                loadingStarted: false, 
                preachError: true
            });
        })
    }



    handleDatesClose = ({ startDate, endDate }) => {
        setTimeout(this.GetPreaches, 200)
    }

    handleDatesChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate });
    }

    handleFocusChange(focusedInput) {
        this.setState({ focusedInput });
    }
    componentWillMount() {
        this.GetPreaches()
    }
    render() {
        let { search, handlePageClick } = this;
        let { pageCount, activePage, isArchive, searchWord } = this.state;
        console.log("Render Started")
        return (
            <ThemeProvider theme={theme}>
                <section id="preach" className={isArchive ? "archive" : ""}>
                    <div className="filter">
                        <div className="filter-container container">

                            <div className="preach-calendar">
                                {isArchive ? "" :
                                    <FormControl className="airbnb-dpicker">
                                        <InputLabel >პერიოდი</InputLabel>
                                        <DateRangePicker
                                            startDate={this.state.startDate}
                                            endDateId="_endDateId"
                                            startDateId="_startDateId"
                                            endDate={this.state.endDate}
                                            onDatesChange={this.handleDatesChange}
                                            onClose={this.handleDatesClose}
                                            onFocusChange={focusedInput => { this.handleFocusChange(focusedInput) }}
                                            focusedInput={this.state.focusedInput}
                                            displayFormat={"D MMM, YYYY"}
                                            hideKeyboardShortcutsPanel={true}
                                            startDatePlaceholderText="აირჩიეთ"
                                            endDatePlaceholderText="აირჩიეთ"
                                            minDate={moment([2010, 0, 1])}
                                            isOutsideRange={day => (moment().diff(day) < 0)}
                                        // hideKeyboardShortcutsPanel={true}

                                        />
                                    </FormControl>
                                }
                                <FormControl >
                                    <InputLabel >მქადაგებელი</InputLabel>
                                    <Select
                                        value={this.state.preacher}
                                        onChange={value => { this.handlePreacher(value) }}

                                    >
                                        <MenuItem value={0}>ყველა</MenuItem>
                                        {
                                            this.state.preachers.map(preacher => {
                                                return (
                                                    <MenuItem value={preacher.id} key={preacher.id} >{preacher.name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                
                                <FormControl >
                                    <TextField id="standard-search" value={searchWord} onChange={search} label="ძებნა" type="search" />
                                </FormControl>
                            </div>
                        </div>
                    </div>

                    <div id="qadagebebi" className="row container mx-auto">
                        {
                            this.state.preachError &&
                                <div id="loading-page">
                                        <p>დაფიქსირდა შეცდომა!</p>
                                </div>
                        }
                        {
                            this.state.loadingStarted ?
                                <div id="loading-page">
                                    <div className="loader">Loading...</div>
                                </div>
                                :
                                this.state.preaches.map((preach, index) => {
                                    return (
                                        <div className={`preach-object animate__fadeInUp custom__delay-${index + 2} animate__animated`} key={index} onClick={() => { this.props.history.push(`/preach-video/${preach.slug}/`) }} >
                                            <div className="preach-bg" style={{ backgroundImage: `url('${preach.imgurl !== "" ? preach.imgurl : "http://qristiani.ge/q/wp-content/uploads/2014/02/IMG_20190113_142103.jpg"}')` }} >
                                            </div>
                                            <div>
                                                <div className="preach-deatails">
                                                    <p>{DateFormatter(preach.sDate)}</p>
                                                    <h1>{SwapLetters(preach.name)}</h1>
                                                    <span className="hoverline"></span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                        }
                        <div className="pag-sec">
                            <Pagination
                                limit={24}
                                offset={(activePage - 1) * 24}
                                previousPageLabel="&#10094; წინა"
                                nextPageLabel="შემდეგი &#10095;"
                                total={pageCount * 24}
                                onClick={(e, offset) => handlePageClick(offset)}
                            />
                        </div>

                    </div>
                </section>

            </ThemeProvider>
        );
    }
}

export default Preach;
