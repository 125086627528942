import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { MenuItem, Popover, MenuList} from '@material-ui/core';
import "../../../assets/scss/sub-header.scss";

export default class SubHeader extends Component {
    state = {
        headerList: this.props.menu,
        anchorEl:null,
        anchorID:null,
        activeSub:null
    }

    showSubmenu =(event,id)=>{
        this.setState({
            anchorEl:event.currentTarget,
            anchorID:id
        })
    }
    hideSubmenu =()=>{
        this.setState({
            anchorEl:null,
            anchorID:null
        })
    }

    handlePageClick(offset) {
        let activePage = (offset/24)+1;
        this.props.history.push(`/youth/camp/0/${activePage}` );
        // this.GetPreaches(activePage, this.state.PreachCategory, this.state.preacher)
    }
    render() {
        const {headerList, anchorEl, anchorID} = this.state;
        const { location, bg } = this.props;
        const {showSubmenu,hideSubmenu} = this;
        return (
            <section id="hero-n1">
                <div className="hero-img-container">
                    
                    <img src={bg} className="heroimage" alt="ministry-hero, pentecostal" />
                    <header id="sub-header">
                        <nav className="sub-nav mx-auto container">
                            <ul className="header-list">
                                {
                                    headerList ? 
                                        headerList.map((Item,i)=>{
                                            return(
                                                Boolean(Item.sub)
                                                ?
                                                <li className={Boolean(Item.sub.find(nav => location.replace(/\/:\w+/g,"/{int}")=== nav.link.replace(/\/[0-9]+/g,"/{int}"))) ? "active" : ""}  key={i}>
                                                    <span 
                                                        className="title" 
                                                        onClick={event=>{showSubmenu(event,i)}}
                                                    >არქივი {location===Item.link ? "*":""}</span>
                                                   <Popover 
                                                       open={i === anchorID}
                                                       anchorEl={anchorEl}
                                                       onClose={hideSubmenu}
                                                       anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                       }}
                                                       transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                       }}
                                                    >
                                                       <MenuList>
                                                            {
                                                                
                                                                Item.sub.map((submenu,j)=>{
                                                                    return(
                                                                        <MenuItem className={location.replace(/\/:\w+/g,"/{int}")=== submenu.link.replace(/\/[0-9]+/g,"/{int}") ? "active sub-nav-item" : " sub-nav-item"} key={j}>
                                                                            <Link to={submenu.link}>{submenu.name}</Link>
                                                                        </MenuItem>
                                                                    )
                                                                })
                                                            }
                                                       </MenuList>
                                                   </Popover>
                                                 
                                               </li>
                                               :
                                               
                                                <li className={location.replace(/\/:\w+/g,"/{int}")=== Item.link.replace(/\/[0-9]+/g,"/{int}") ? "active" : ""} key={i}>
                                                    <Link to={Item.link}>{Item.name}</Link>
                                                </li>
                                            )
                                        })
                                    :""
                                }

                            </ul>
                        </nav>
                    </header>
                </div>
            </section>
        )
    }
}
