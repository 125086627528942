import React, { Component } from 'react';

import Pagination from "material-ui-flat-pagination";
import {
    LocationOn,
    SlowMotionVideo,
    PhotoLibrary,
    Warning,
    VideoLibraryOutlined
} from '@material-ui/icons';
import {
    TextField,
    MenuItem,
    InputLabel,
    Select,
    FormControl
} from '@material-ui/core';
import { Link } from "react-router-dom";
import Axios from 'axios';

class Camp extends Component {

    state = {
        pageCount: 4,
        page: this.props.match.params.page ? this.props.match.params.page : 1,
        year: this.props.match.params.year ? this.props.match.params.year : 0,
        place: this.props.match.params.place ? this.props.match.params.place : 0,
        category: this.props.match.params.category ? this.props.match.params.category : 0,
        searcht: 0,
        camps: [

        ],
        locations: [
            {
                ID: 1,
                title: 'ცემი',
            },
            {
                ID: 2,
                title: 'ბაკურიანი',
            },
            {
                ID: 3,
                title: 'ბაღდათი',
            },
            {
                ID: 4,
                title: 'ურეკი',
            },
            {
                ID: 5,
                title: 'ატენი',
            },
            {
                ID: 6,
                title: 'ბაზალეთი',
            },
        ],
        years: [
            2020, 2019,
            2018, 2017,
            2016, 2015,
            2014, 2013,
            2012, 2011,
            2010, 2009,
            2008, 2007
        ],
        categories: [
            {
                ID: 10,
                title: 'მოზარდები',
            },
            {
                ID: 11,
                title: 'ბავშვები',
            },

            {
                ID: 12,
                title: 'საოჯახო',
            }
        ]
    }

    handleYear = ({ target }) => {
        let year = target.value
        this.setState({ year })
        const { page, place, category } = this.state;
        this.props.history.push(`/camps/${page}/${year}/${place}/${category}`)
    }

    handleCategory = ({ target }) => {
        let category = target.value
        this.setState({ category })
        const { page, place, year } = this.state;
        this.props.history.push(`/camps/${page}/${year}/${place}/${category}`)
    }

    handleLocation = ({ target }) => {
        let place = target.value
        this.setState({ place })
        const { page, year, category } = this.state;
        this.props.history.push(`/camps/${page}/${year}/${place}/${category}`)
    }

    handlePagination = (_el, page_offset) => {
        let page = (page_offset / 6) + 1;
        this.setState({ page })
        const { year, place, category } = this.state;
        this.props.history.push(`/camps/${page}/${year}/${place}/${category}`)
    }

    handlePreach = (year = this.state.year, place_id = this.state.place, category_id = this.state.category, page = this.state.page) => {
        // console.log(`${year}/${place_id}/${category_id}/0/${page}`)
        Axios.get(`https://q.qristianinews.ge/search/all/camps/${place_id}/${year}/${category_id}/${this.state.searcht}/${page}`)
            .then(({ data }) => {
                console.log(data)
                this.setState({
                    camps: data.allcamps,
                    locations: data.locs,
                    years: data.years,
                })
            })
            .catch(err => {

            })
    }
    componentWillMount() {
        this.handlePreach()
    }
    render() {
        const { pageCount, page, camps, locations, years, place, year, category, categories } = this.state;
        const { handleYear, handleLocation, handlePagination, handleCategory } = this;
        return (
            <section id="camps">
                <div className="filter">
                    <div className="filter-container container">

                        <div className="preach-calendar">
                            <FormControl >
                                <InputLabel >ლოკაცია</InputLabel>
                                <Select
                                    value={place}
                                    onChange={handleLocation}

                                >
                                    <MenuItem value={0}>ყველა</MenuItem>
                                    {
                                        locations.map(({ keywords }, i) => {
                                            return <MenuItem value={i} key={i}>{keywords}</MenuItem>
                                        })

                                    }
                                </Select>
                            </FormControl>
                            <FormControl >
                                <InputLabel >წელი</InputLabel>
                                <Select
                                    value={year}
                                    onChange={handleYear}

                                >
                                    <MenuItem value={0}>ყველა</MenuItem>
                                    {
                                        years.map(year => {
                                            return <MenuItem value={year} key={year}>{year}</MenuItem>
                                        })

                                    }
                                </Select>
                            </FormControl>
                            <FormControl >
                                <InputLabel >კატეგროია</InputLabel>
                                <Select
                                    value={category}
                                    onChange={handleCategory}

                                >
                                    <MenuItem value={0}>ყველა</MenuItem>
                                    {
                                        categories.map(({ ID, title }) => {
                                            return <MenuItem value={ID} key={ID}>{title}</MenuItem>
                                        })

                                    }
                                </Select>
                            </FormControl>

                            <FormControl >
                                <TextField id="standard-search" value=" " label="ძებნა" type="search" />
                            </FormControl>

                        </div>
                    </div>
                </div>

                <div className="camp-container row container mx-auto">
                    {/* <article className="camp-item animate__fadeInLeft custom__delay-1 animate__animated camp-error-text  mx-auto">
                        <Warning />
                        <p>წამოღების დროს დაფიქსირდა შეცდომა</p>
                    </article> */}
                    {
                        camps.map((camp, i) => {
                            return (
                                <article key={camp.id} className={`camp-item animate__fadeInLeft custom__delay-${i + 2} animate__animated`} >
                                    <div className="camp-img-sec">
                                        <img src={camp.imgurl} alt={camp.name} />
                                    </div>
                                    <div className="camp-details">
                                        <h3 className="camp-year">{camp.sDate.slice(6)}</h3>
                                        <h2 className="camp-title">{camp.name}</h2>
                                        <div className="camp-location-section">
                                            <LocationOn />
                                            <h3 className="camp-loc-title">{camp.keywords}</h3>
                                        </div>
                                        <div className="gallery-section">
                                            {
                                                Boolean(camp.videocount) &&
                                                <Link to={`/camps/video/${camp.id}/${camp.slug}`}>
                                                    <div className="gallery-link-item">
                                                        <VideoLibraryOutlined />
                                                        <p>ვიდეო</p>
                                                    </div>
                                                </Link>
                                            }
                                            {
                                                true &&
                                                <Link to={`/camps/photo/${camp.id}/${camp.slug}`}>
                                                    <div className="gallery-link-item">
                                                        <PhotoLibrary />
                                                        <p>ფოტო</p>
                                                    </div>
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                </article>
                            )
                        })
                    }
                </div>

                <div className="pag-sec">
                    {
                        pageCount !== 1 ?
                            <Pagination
                                limit={6}
                                offset={(page - 1) * 6}
                                previousPageLabel="&#10094; წინა"
                                nextPageLabel="შემდეგი &#10095;"
                                total={pageCount * 6}
                                onClick={handlePagination}
                            />
                            :
                            <div className="mb-5"></div>
                    }
                </div>
            </section>
        );
    }
}

export default Camp;