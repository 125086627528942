import React, { Component } from 'react';
import VideoPlayer from '../partials/player';
import axios from "axios";
import swapLetters from '../../functions/swap-letters-en-ka';
import dateFormatter from '../../functions/date-formatter';
import ShareIcon from '@material-ui/icons/Share';
import SocialModal from '../partials/bootstrap_modal';

export class PreachVideo extends Component {
    state = {
        preach: {},
        preacher: {
            description: "",
            id: null,
            imgurl: "",
            name: ""
        },
        openModal: false,
        videoUrl: ""
    }


    shareOpen = () => {
        this.setState({ openModal: true })
    }
    shareClose = () => {
        this.setState({ openModal: false })
    }

    getPreach() {
        axios.get(`https://q.qristianinews.ge/search/all/singleSermon/${this.props.match.params.slug}`)
            .then(response => {
                console.log(response);
                let preach = response.data.qadageba;
                let preacher = response.data.mqadagebeli[0];
                let videoUrl = response.data.qadageba.videoUrl;

                this.setState({
                    preach,
                    preacher,
                    videoUrl
                });
            })
    }

    componentDidMount() {
        this.getPreach()
    }

    render() {
        let { preacher, videoUrl, preach, openModal } = this.state;
        let { shareClose, shareOpen } = this;

        return (
            <div className="container">
                <div id="preach-video" >
                    {
                        this.state.videoUrl !== "" ? <VideoPlayer uri={videoUrl} /> : ""
                    }
                    <div className="preach-label-share">
                        <h1 className="preach-label">{swapLetters(preach.name)}</h1>

                        <div className="preach-share" >
                            <ShareIcon onClick={shareOpen} />
                        </div>
                    </div>
                    {/* <!-- DATE & PREACHER --> */}
                    <div className="preacher-img">
                        <img className="preacher-avatar" src={preacher.imgurl !== "" ? preacher.imgurl : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLhJCWucBXkPVb3Tw7AIc4396FDoC1IFEhlfEsiqqehHshqu0Z&s"} alt="Avatar" />
                        <div className="preacher-date">
                            <h2>{preacher.name} </h2>
                            <p>{videoUrl !== "" ? dateFormatter(preach.sDate) : ""}</p>
                        </div>
                    </div>
                    {/* <!-- PREACH COMMENT --> */}
                    <div className="preach-comment">
                        <h5 >კომენტარი</h5>
                        <p>
                            საქართველოს სახარების რწმენის ეკლესია; <br />
                            მქადაგებელი - გია ჯვარშეიშვილი <br />
                            ღვთისმსახურება ტარდება ყოველ კვირას 11 და 16 თ.ესაათზე; <br />
                            მისამართი: მეტრო დიდუბე (ზედა ამოსასვლელი), ერისთავის #1; <br />
                            Emails : contact@qristiani.ge contact@holybible.ge
                        </p>
                    </div>
                </div>

                <div className="facebook-sec">
                    {/* <!-- <div className="fb-comments" data-width="1100"  data-numposts="5"></div> --> */}
                    <div className="fb-comments" data-href="http://localhost:3000/main_worship/video" data-width="100%" data-numposts="5"></div>
                </div>
                <SocialModal open={openModal} onClose={shareClose} />
            </div>
        );
    }
}

export default PreachVideo;
