import React, { Component } from "react";
import "../../assets/scss/pages/about.scss";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";

import History from "./history";
import Faith from "./faith";
import Leaders from "./leaders";
import C_Leader from "./custom_leader";
import Education from "./education";
import Churches from "./churches";

class About extends Component {
  componentDidMount() {
    document.querySelector("#footer").style.display = "none";
  }
  componentWillUnmount() {
    document.querySelector("#footer").style.display = "";
    this.unlisten();
  }
  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      console.log("on route change");
    });
  }
  render() {
    return (
      <Router>
        <main
          id="about"
          style={{
            backgroundImage: `url(${require("../../assets/img/sult.jpg")}`,
          }}
        >
          <header className="about-header">
            <nav className="about-nav container">
              <ul>
                <li className="about-nav-item custom__delay-2 animate__fadeIn animate__animated">
                  <Link className="active " to="/about_us">
                    ისტორია
                  </Link>
                </li>
                <li className="about-nav-item custom__delay-4 animate__fadeIn animate__animated">
                  <Link to="/about_us/faith">მრწამსი</Link>
                </li>
                <li className="about-nav-item custom__delay-6 animate__fadeIn animate__animated">
                  <Link to="/about_us/leaders">ხელმძღვანელები</Link>
                </li>
                <li className="about-nav-item custom__delay-8 animate__fadeIn animate__animated">
                  <Link to="/about_us/education">განათლება</Link>
                </li>
                <li className="about-nav-item custom__delay-10 animate__fadeIn animate__animated">
                  <Link to="/about_us/churches">ეკლესები</Link>
                </li>
              </ul>
            </nav>
          </header>
          <Switch>
            <Route exact path="/about_us" component={History} />
            <Route exact path="/about_us/faith" component={Faith} />
            <Route exact path="/about_us/leaders" component={Leaders} />
            <Route exact path="/about_us/leaders/:slug" component={C_Leader} />
            <Route exact path="/about_us/education" component={Education} />
            <Route exact path="/about_us/churches" component={Churches} />
          </Switch>
        </main>
      </Router>
    );
  }
}

export default About;
